<!-- 新增/修改题目分类页面 -->
<template>
  <el-dialog
    v-model="closeEditPage"
    :title="openPageTitle == 'add' ? '新增题库分类' : '编辑题库分类'"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    width="50%"
    style="overflow-y: auto"
  >
    <div v-loading="loading" style="max-width: 500px">
      <el-form
        ref="ruleFormRef"
        :model="formInfo"
        :rules="rules"
        :label-position="labelPosition"
        label-width="130px"
      >
        <el-form-item label="选择上级分类：" prop="reid">
          <!-- 题目分类树下拉框 -->
          <QuestionCateTreeSelect
            ref="cateTreeSelectRef"
            :specifyId="2"
            :defaultExpandedKeys="[formInfo.reid]"
            :filterable="false"
            @getValue="handleChangeQuestionCateSelect"
            :style="{ width: '100%' }"
          />
        </el-form-item>
        <el-form-item label="分类名称：" prop="name">
          <el-input
            v-model="formInfo.name"
            placeholder="请输入分类名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="排序：" prop="sortnum">
          <el-input
            v-model="formInfo.sortnum"
            placeholder="请输入排序"
            clearable
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          v-if="openPageTitle == 'edit'"
          type="primary"
          @click="onSubmit"
          :disabled="clickFlag"
        >
          立即修改
        </el-button>
        <el-button
          v-else
          type="primary"
          @click="onSubmit"
          :disabled="clickFlag"
        >
          立即添加
        </el-button>
        <el-button @click="onCancel">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

import { QuestionCateTreeSelect } from "@/components";
import { isNotEmpty } from "@/utils/helper";

import { getQuestionCateInfo, postQuestionCateEdit } from "@/api/questions";

export default defineComponent({
  /**
   * 使用组件
   */
  components: {
    QuestionCateTreeSelect,
  },
  name: "CategoryEditView",

  data() {
    return {
      /**
       * loading.加载 true显示 false不显示 默认false
       * closeEditPage.页面显示  true显示 false不显示 默认false
       * clickFlag.设置按钮不可点击（disable: false）
       * labelPosition.输入内容文字提示位置
       */
      loading: false,
      id: 0,
      openPageTitle: "",

      closeEditPage: false,
      clickFlag: false,
      labelPosition: "right",

      /**
       * 表单填写规则
       */
      /**
       * 表单填写规则
       */
      rules: {
        name: [
          {
            required: true,
            message: "请输入题库分类名称",
            trigger: "blur",
          },
          {
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
            message: "仅支持中文、数字、字母、下划线",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        reid: [
          {
            required: true,
            message: "请选择上级分类",
            trigger: "blur",
          },
        ],
        sortnum: [
          {
            pattern: /^\+?[0-9]\d*$/,
            message: "请输入正整数",
            trigger: "blur",
          },
        ],
      },
      /**
       * 输入的内容
       */
      formInfo: {
        id: 0,
        name: "",
        reid: 2,
        sortnum: 0,
      },
    };
  },
  methods: {
    initFunEdit(id: number, pid: number, openPageTitle: string) {
      this.showTrueOrFalse(true);
      this.id = id;
      this.openPageTitle = openPageTitle;

      /**
       * 初始化参数
       */
      this.formInfo = {
        id: 0,
        name: "",
        reid: Number(this.id ?? 2),
        sortnum: 0,
      };

      if (this.openPageTitle == "edit") {
        this.formInfo.reid = Number(pid ?? 2);

        this.getQuestionCateInfo();
      }

      this.showTrueOrFalse(false);
      this.closeEditPage = true;
    },

    /**
     * 获取题库分类详情接口
     */
    async getQuestionCateInfo() {
      getQuestionCateInfo({ id: this.id }).then((res: any) => {
        if (isNotEmpty(res)) {
          this.formInfo = res.data;
        }
      });
    },

    /**
     * 编辑接口
     */
    async postQuestionCateEdit(params: any) {
      postQuestionCateEdit(params).then((res: any) => {
        if (100 == res.errcode) {
          ElMessage.success(res.msg);
          this.$emit("searchWhere");
        }

        setTimeout(() => {
          this.closeEditPage = false;
        }, 1000);
      });
    },

    /**
     * 选择分类后，回调方法
     */
    handleChangeQuestionCateSelect(reid: any) {
      this.formInfo.reid = reid ?? 2;
    },

    /**
     * 点击保存，调用ajax保存数据后，使用父级页面方法关闭当前页
     */
    onSubmit() {
      this.showTrueOrFalse(true);

      interface obj {
        [id: string]: any;
      }

      /**
       * 定义传递的参数
       */
      let params: obj = {
        name: this.formInfo.name.trim(),
        reid: this.formInfo.reid,
        sortnum: this.formInfo.sortnum,
      };

      if (!params.name) {
        this.showTrueOrFalse(false, "请输入题库分类名称");
        return false;
      }

      /**
       * 存在数据并且 id存在 为修改  不存在为添加
       */
      if (this.formInfo.id) {
        params.id = this.formInfo.id;
      }

      (this.$refs["ruleFormRef"] as any).validate((valid: any) => {
        if (valid) {
          console.log("submit!!", valid);
          this.postQuestionCateEdit(params);
        } else {
          console.log("error!!", valid);
          return false;
        }
      });

      this.showTrueOrFalse(false);
    },

    /**
     * 点击取消，使用父级页面方法关闭当前页
     */
    onCancel() {
      this.$emit("update:id", 0);
      this.$emit("update:openPageTitle", "");
      this.closeEditPage = false;
    },

    /**
     * 显示还是隐藏
     * @param bools true 显示限制，false 不显示不限制
     * @param msg 提示消息
     */
    showTrueOrFalse(bools = true, msg = "") {
      if (bools) {
        this.loading = bools;
        this.clickFlag = bools;
      } else {
        /**
         * 消息提示
         */
        if (msg) {
          ElMessage.error(msg);
        }
        /**
         * 隐藏全局遮蔽
         */
        setTimeout(() => {
          this.loading = bools;
        }, 500);

        /**
         * 取消禁用点击
         */
        setTimeout(() => {
          this.clickFlag = bools;
        }, 2000);
      }
    },
  },
});
</script>

<style lang="less"></style>
