<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!-- <router-view /> -->
  <el-config-provider :locale="zhCn">
    <router-view />
  </el-config-provider>
</template>
<script setup lang="ts">
import { RouterView } from "vue-router";
import zhCn from "element-plus/es/locale/lang/zh-cn";

/**
 * 分页 自定义文字（使用默认文字不用设置）
 */
zhCn.el.pagination = {
  goto: "跳转",
  pagesize: "条/页",
  total: "共{total}条",
  pageClassifier: "",
  page: "",
  prev: "",
  next: "",
  currentPage: "",
  prevPages: "",
  nextPages: "",
  deprecationWarning: "",
};

/**
 * 处理el-table的ResizeObserver loop limit exceeded问题
 */
const debounce = (fn: any, delay: any) => {
  let timer: number | undefined;
  // eslint-disable-next-line no-underscore-dangle, no-invalid-this
  let $this = this as any;

  return function () {
    let context: any = $this;
    let args = arguments;

    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
// eslint-disable-next-line no-underscore-dangle
const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  // eslint-disable-next-line no-undef
  constructor(callback: ResizeObserverCallback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
}
#app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-pagination {
  --el-pagination-button-width: 28px !important;

  .el-pagination__sizes {
    margin-left: 5px;
  }
  .btn-prev {
    margin-left: 5px !important;
  }
  .el-pagination__jump,
  .is-last {
    margin-left: 5px;
  }
  .el-select .el-input {
    width: 100px !important;
  }
  button {
    padding: 0px !important;
  }
  .el-pager li {
    padding: 0px !important;
  }
}
</style>
