<template>
  <el-select
    class="flow-select"
    v-model="filters.filTitle"
    clearable
    placeholder="请选择分类"
    :style="style"
  >
    <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input>
    <el-option
      hidden
      :label="filters.filTitle"
      :value="filters.filId"
    ></el-option>
    <el-tree
      ref="selectTree"
      class="flow-tree st-overflow-x st-overflow-y"
      :props="propsRule"
      :data="listData"
      node-key="id"
      :filterable="filterable"
      :disabled="disabled"
      :auto-expand-parent="true"
      :highlight-current="true"
      :default-expanded-keys="defaultExpandedKeys"
      :filter-node-method="filterNode"
      @node-click="handleNodeClick"
    >
    </el-tree>
  </el-select>
</template>
<script lang="ts" steup>
import { defineComponent } from "vue";

import { isNotEmpty } from "@/utils/helper";

import { getTypeList } from "@/api/exam";

export default defineComponent({
  name: "ExamTypeTreeSelect",
  props: {
    defaultExpandedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    paramId: {
      type: Number,
      default: 0,
    },
    style: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      propsRule: {
        children: "children",
        label: "label",
        value: "id",
      },
      listData: [] as any,
      filterText: "",
      filters: {
        filTitle: "",
        filId: 0,
      },
    };
  },
  watch: {
    filterText(val) {
      if (this.$refs.selectTree) {
        (this.$refs.selectTree as any).filter(val);
      }
    },
  },
  created() {
    this.filterText = "";
    this.filters = {
      filTitle: "",
      filId: 0,
    };

    this.initGetTypeList({});
  },
  methods: {
    /**
     * 获取分类
     */
    async initGetTypeList(parameter: any) {
      getTypeList(parameter).then((res: any) => {
        if (isNotEmpty(res)) {
          this.listData = this.handleIndustryData(res.data);
          this.listData.unshift({
            id: 0,
            label: "顶级分类",
            children: [],
          });

          this.$nextTick(() => {
            if (this.$refs.selectTree) {
              (this.$refs.selectTree as any).setCurrentKey(this.filters.filId);
            }
          });
        }
      });
    },

    /**
     * 设置选中的内容
     * @param list
     */
    handleIndustryData(list: any) {
      let data = list.map((item: any) => {
        if (
          this.defaultExpandedKeys.length > 0 &&
          this.defaultExpandedKeys.includes(Number(item.id))
        ) {
          this.filters.filTitle = item.title;
          this.filters.filId = Number(item.id);
        }

        return {
          id: Number(item.id),
          label: item.title,
          children: item.children ? this.handleIndustryData(item.children) : [],
        };
      });

      return data;
    },
    /**
     * 点击选择
     * @param data
     */
    handleNodeClick(data: any) {
      if (data.id == -1) {
        data.id = 0;
      }

      this.filters.filTitle = data.label ?? data.title;
      this.filters.filId = Number(data.id);

      this.$emit("getValue", data.id);
    },
    /**
     * 关键字过滤
     */
    filterNode(value: any, data: any) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  },
});
</script>

<style lang="less" scoped>
@import "../../styles/less/flow-tree.less";

:deep(.flow-select .tree-select__option) {
  &.el-select-dropdown__item {
    height: auto;
    line-height: 1;
    padding: 0;
    background-color: #fff;
  }
}
:deep(.el-select-dropdown__wrap) {
  min-width: 400px;
  max-height: 600px;
}
:deep(.el-select-dropdown__list) {
  margin: 6px 10px !important;
  padding: 20 !important;
}

:deep(.tree-select__tree) {
  padding: 8px 50px;
  font-weight: 400;
  &.tree-select__tree--radio {
    .el-tree-node.is-current > .el-tree-node__content {
      // color: $mainColor;
      font-weight: 700;
    }
  }
}
</style>
