/**
 *  @description: 工具函数  数组
 */

/**
 * 数组分组
 * @param {*} arr 需要分组的数组
 * @param {*} fn 分组的函数
 * @return {Array} list
 */
export function groupBy(arr: any, fn: any) {
  const groups = {} as any;

  arr.forEach(function (val: any) {
    var group = JSON.stringify(fn(val));

    groups[group] = groups[group] || [];
    groups[group].push(val);
  });

  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

/**
 * 数组根据某个属性分组
 * @param {*} list 需要分组的数组
 * @param {*} groupId 需要分组的数组的属性
 * @return {Array} list
 */
export function arrayGroupBy(list: Array<any>, groupId: string) {
  const sorted = groupBy(list, function (item: any) {
    return [item[groupId]];
  });

  return sorted;
}

/*
 * JSON数组去重
 * @param: [array] json Array
 * @param: [string] 唯一的key名，根据此键名进行去重
 */
export function uniqueArray(array: any[], key: string) {
  let result = [array[0]];

  for (let i = 1; i < array.length; i++) {
    let item = array[i];
    let repeat = false;

    for (let j = 0; j < result.length; j++) {
      if (item[key] == result[j][key]) {
        repeat = true;
        break;
      }
    }

    if (!repeat) {
      result.push(item);
    }
  }

  return result;
}

/**
 * 两个数组去重
 * @param {Array} originList 原始数组
 * @param {Array} currentList 当前数组
 * @param {String} sortKey 去重字段
 * @return {Array} 返回新数组中不重复的部分
 */
export function Duplicate(
  originList: any[],
  currentList: any[],
  sortKey = "id"
) {
  const map = {} as any;

  originList.forEach((e: { [x: string]: string | number }) => {
    map[e[sortKey]] = true;
  });

  return currentList.filter(
    (e: { [x: string]: string | number }) => !map[e[sortKey]]
  );
}

/**
 * 数组包数组去重
 * @param {Array} arr 原始数组
 * @return {Array} 返回去重后的新数组
 */
export function unique(arr: any[]) {
  // eslint-disable-next-line no-shadow
  arr = arr.filter((item: any[]) => item.every((item) => item)); // 去除[0,0]

  for (let i = 0, len = arr.length; i < len; i++) {
    // eslint-disable-next-line no-shadow
    for (let j = i + 1, len = arr.length; j < len; j++) {
      if (JSON.stringify(arr[i]) === JSON.stringify(arr[j])) {
        arr.splice(j, 1);
        j--; // 每删除一个数j的值就减1
        len--; // j值减小时len也要相应减1（减少循环次数，节省性能）
      }
    }
  }

  return arr;
}

/**
 * 数组元素为对象的去重方法 ES5方法
 * @param arr  原数组
 * @param type  根据元素的某个属性进行去重
 * @returns {Array} 返回去重后的新的数组
 */
export function Es5duplicate(arr: string | any[], type: string | number) {
  let newArr = [];
  let tArr = [];

  if (arr.length == 0) {
    return arr;
  } else if (type) {
    for (let i = 0; i < arr.length; i++) {
      if (!tArr[arr[i][type]]) {
        newArr.push(arr[i]);
        tArr[arr[i][type]] = true;
      }
    }

    return newArr;
  } else {
    for (let j = 0; j < arr.length; j++) {
      if (!tArr[arr[j]]) {
        newArr.push(arr[j]);
        tArr[arr[j]] = true;
      }
    }

    return newArr;
  }
}
/**
 * 数组元素为对象的去重方法 ES6方法
 * @param arr  原数组
 * @param type  根据元素的某个属性进行去重
 * @returns {Array} 返回去重后的新的数组
 */
export function Es6duplicate(arr: any, type: any) {
  var hash = {} as any;

  return arr.reduce(function (item: any, next: any) {
    // eslint-disable-next-line no-unused-expressions
    hash[next[type]] ? "" : (hash[next[type]] = true && item.push(next));
    return item;
  }, []);
}

/**
 * 递归实现无限极分类 数组树状
 * @param $array 数组数据
 * @param $recursionId 指定 递归 id字段
 * @param $judgePid 指定 判断 pid字段
 * @param $pid 父ID
 * @param $type 0 其他分类， 1题目分类处理
 * @param $disabled 是否禁用
 * @param $maxDepth 最大递归深度 默认没有深度限制
 * @return array 分好类的数组 直接遍历即可
 */
export function recArrTree(
  arr: Array<any>,
  recursionId = "id",
  judgePid = "pid",
  pid = 0,
  disabled = false,
  maxDepth = Infinity
) {
  if (arr.length === 0 || maxDepth <= 0) {
    return [];
  }

  let tree = [];
  let tempArr = [...arr];

  for (let index = 0; index < tempArr.length; index++) {
    const element = tempArr[index];

    /**
     * 如果父级id等于0
     */
    if (element[judgePid] == pid) {
      if (disabled) {
        element["disabled"] = false;
      }

      element["children"] = recArrTree(
        tempArr,
        recursionId,
        judgePid,
        element[recursionId],
        disabled,
        maxDepth - 1
      );

      if (element["children"]) {
        element["expand"] = true;
      }

      tree.push(element);
      tempArr.splice(index, 1);
      index--;
    }
  }

  return tree;
}
