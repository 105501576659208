import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "el-dropdown-span"
}
const _hoisted_2 = {
  key: 0,
  class: "menu-sub-div"
}
const _hoisted_3 = { class: "el-dropdown-span" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.routes, (item, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "menu-div",
      key: index
    }, [
      (
        item.meta &&
        !item.meta.hidden &&
        item.children &&
        item.redirect == '/home/index'
      )
        ? (_openBlock(), _createBlock(_component_el_sub_menu, {
            key: 0,
            index: item.redirect,
            route: item.redirect,
            item: item.meta && item.meta.title,
            tabindex: "menu-746893"
          }, {
            title: _withCtx(() => [
              ($props.isCollapse)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta && item.meta.icon), {
                    key: 0,
                    class: "icons",
                    style: {"margin-left":"10px"}
                  }))
                : _createCommentVNode("", true),
              (!$props.isCollapse)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                    _createVNode(_component_el_menu_item, {
                      index: item.redirect,
                      route: item.redirect
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta && item.meta.icon), { class: "icons" })),
                        _createTextVNode(" " + _toDisplayString(item.meta && item.meta.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["index", "route"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              ($props.isCollapse)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_el_menu_item, {
                      index: item.redirect,
                      route: item.redirect,
                      item: item.meta && item.meta.title + `menu-sub-div-${index}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.meta && item.meta.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["index", "route", "item"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["index", "route", "item"]))
        : (
        item.meta &&
        !item.meta.hidden &&
        item.children &&
        item.redirect != '/home/index'
      )
          ? (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 1,
              index: item.redirect,
              route: item.redirect,
              item: item.meta && item.meta.title
            }, {
              title: _withCtx(() => [
                ($props.isCollapse)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta && item.meta.icon), {
                      key: 0,
                      class: "icons"
                    }))
                  : _createCommentVNode("", true),
                (!$props.isCollapse)
                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                      key: 1,
                      class: "el-dropdown-p",
                      "hide-on-click": false,
                      placement: "right-start",
                      tabindex: "menu-224326"
                    }, {
                      dropdown: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (sub, subIndex) => {
                          return (_openBlock(), _createElementBlock("div", { key: subIndex }, [
                            (sub.meta && !sub.meta.hidden && !sub.children)
                              ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                  key: 0,
                                  class: "el-dropdown-item-p",
                                  item: 
                  sub.meta && sub.meta.title + `menu-dropdown-sub-${subIndex}`
                
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_menu_item, {
                                      route: item.path + '/' + sub.path,
                                      index: item.path + '/' + sub.path
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(sub.meta && sub.meta.title), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["route", "index"])
                                  ]),
                                  _: 2
                                }, 1032, ["item"]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.meta && item.meta.icon), { class: "icons" })),
                          _createTextVNode(" " + _toDisplayString(item.meta && item.meta.title), 1)
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (sub, subIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "menu-sub-div",
                    key: subIndex
                  }, [
                    (sub.meta && !sub.meta.hidden && !sub.children)
                      ? (_openBlock(), _createBlock(_component_el_menu_item, {
                          key: 0,
                          route: item.path + '/' + sub.path,
                          index: item.path + '/' + sub.path,
                          item: sub.meta && sub.meta.title + `menu-sub-div-${subIndex}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(sub.meta && sub.meta.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["route", "index", "item"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["index", "route", "item"]))
          : _createCommentVNode("", true)
    ]))
  }), 128))
}