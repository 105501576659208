import { useAxios } from "@/utils/useAxios";

const request = useAxios();

/**
 * 题库题目列表
 * @param params
 * @returns
 */
export const getQuestionsList = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/questions/list",
    data,
  });

  return res && res.data;
};

/**
 * 题库题目信息
 * @param params
 * @returns
 */
export const getQuestionsInfo = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/questions/info",
    params,
  });

  return res && res.data;
};

/**
 * 导入题库题目信息
 * @param data
 * @returns
 */
export const postImportExcel = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/questions/import-excel", data });

  return res && res.data;
};

/**
 * 编辑题库题目信息
 * @param data
 * @returns
 */
export const postQuestionsEdit = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/questions/edit",
    data,
  });

  return res && res.data;
};

/**
 * 删除题库题目
 * @param data
 * @returns
 */
export const postQuestionsDelete = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/questions/delete",
    data,
  });

  return res && res.data;
};

/**
 * 题库分类列表
 * @param params
 * @returns
 */
export const getQuestionCateList = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/questions/category-list",
    params,
  });

  return res && res.data;
};

/**
 * 题库分类详情
 * @param params
 * @returns
 */
export const getQuestionCateInfo = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/questions/category-info",
    params,
  });

  return res && res.data;
};

/**
 * 题库分类编辑
 * @param params
 * @returns
 */
export const postQuestionCateEdit = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/questions/category-edit",
    data,
  });

  return res && res.data;
};

/**
 * 题库分类删除
 * @param params
 * @returns
 */
export const postQuestionCateDelete = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/questions/category-delete",
    data,
  });

  return res && res.data;
};
