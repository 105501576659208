<template>
  <div>
    <div v-show="!tinymceShow" v-html="tinycontent"></div>
    <Editor
      id="my-editor"
      ref="tinymceContent"
      v-show="tinymceShow"
      :init="tinyinit"
      v-model="tinycontent"
      @change="handleContentChange"
      :key="tinymceFlag"
      :disabled="disabled"
    />
    <el-dialog
      v-show="tinymceShow"
      v-model="centerDialogVisible"
      :title="uploadDialogTitle"
      width="30%"
      destroy-on-close
      center
    >
      <el-upload
        class="upload-demo"
        :accept="accept"
        drag
        :show-file-list="false"
        :before-upload="handleBeforeUpload"
        :http-request="handleUpload"
        :on-success="handleSuccess"
        :on-error="handleError"
      >
        <el-icon v-if="!uploading" class="el-icon--upload">
          <upload-filled />
        </el-icon>
        <div v-if="!uploading" class="el-upload__text">
          拖拽文件或者 <em>选择上传</em>
        </div>
        <el-progress
          v-if="uploading"
          type="circle"
          class="process"
          :show-text="false"
          status="success"
          :percentage="process"
        />
        <template #tip>
          <div class="el-upload__tip">
            {{ uploadTip }}
          </div>
        </template>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from "vue";
import { ElMessage } from "element-plus";
import COS from "cos-js-sdk-v5";

import { isNotEmpty } from "@/utils/helper";

import { tencentCloudCosConfig } from "@/api/uploads";
import Editor from "@tinymce/tinymce-vue";

import {
  TINY_FONT_FAMILY_FORMATS,
  TINY_FONT_SIZE_FORMATS,
  TINY_PLUGINS,
  TINY_TOOLBAR,
} from "./constants";

export default defineComponent({
  components: {
    Editor,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tinymceShow: {
      type: Boolean,
      default: true,
    },
    /**
     * 插件
     */
    plugins: {
      type: [String, Array],
      default: TINY_PLUGINS,
    },

    /**
     * 工具栏
     */
    toolbar: {
      type: [String, Array],
      default: TINY_TOOLBAR,
    },

    toolbarMode: {
      type: String,
      default: "wrap",
    },

    /**
     * 字体样式
     */
    fontFamilyFormats: {
      type: String,
      default: TINY_FONT_FAMILY_FORMATS,
    },

    /**
     * 字号
     */
    fontSizeFormats: {
      type: String,
      default: TINY_FONT_SIZE_FORMATS,
    },

    /**
     * 编辑器高
     */
    height: {
      type: Number,
      default: () => {
        return (
          parseInt(
            window.getComputedStyle(document.querySelector("#app") as Element)
              .height ?? 400
          ) - 256
        );
      },
    },

    /**
     * 编辑器宽
     */
    width: {
      type: Number,
      default: () => {
        return (
          parseInt(
            window.getComputedStyle(document.querySelector("#app") as Element)
              .width ?? 700
          ) - 200
        );
      },
    },

    /**
     * 编辑器内容
     */
    content: {
      type: String,
      default: () => {
        return ref<string>("");
      },
    },
  },
  data() {
    return {
      config: {} as any,
      tinymceFlag: 0,
      uploadTip: "",
      uploading: false,
      process: 0,
      accept: "",
      insertTagName: "image",
      uploadDialogTitle: "",
      mime: ref({}) as any,
      centerDialogVisible: ref(false),
      tinycontent: ref<string>(""),
      editor: ref<any>(),
      tinyinit: ref({
        height: this.height,
        width: this.width,
        min_height: 600,
        max_height: 1000,
        min_width: 400,
        max_width: 1200,
        resize: true, // 不允许用户调整大小
        language: "zh-Hans", // 汉化
        branding: false, // 隐藏tinymce右下角水印
        convert_urls: false, // 不自动转换链接地址
        plugins: this.plugins, // 插件
        contextmenu: "", // 上下文菜单
        menubar: false, // 菜单栏
        toolbar_mode: this.toolbarMode, // 工具栏多行显示样式
        toolbar: this.toolbar, // 工具栏
        font_family_formats: this.fontFamilyFormats, // 字体选择
        font_size_formats: this.fontSizeFormats, // 字号选择
        forced_root_block: '', // 清除标签显示
        quickbars_insert_toolbar: '', // 光标在【空的一行】时，显示可以快速插入的工具项。 默认'quickimage quicktable'
        content_style: '* { padding:0; margin:0; } img { width: auto; max-width:100% !important }', // 初始化赋值
        images_upload_url: '', // 图片上传地址
        // paste_data_images: false, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像。

        setup: (editor: any) => {
          this.editor = editor;

          /**
           * 单文件上传
           */
          editor.ui.registry.addButton("image", {
            icon: "image",
            tooltip: "上传图片",
            onAction: this.selectLocalSingleImage,
          });

          /**
           * 视频上传
           */
          editor.ui.registry.addButton("video", {
            icon: `video`,
            tooltip: "视频上传",
            onAction: this.selectLocalVideo,
          });
        },
        /**
         * 此处为图片上传处理函数 该方法为工具栏上传图片  需要走后台上传接口
         */
        images_upload_handler: this.imagesUploadHandler,
      }),
    };
  },
  activated() {
    this.tinymceFlag = this.tinymceFlag == 0 ? 1 : 0;
  },
  /**
   * 数据监听
   */
  watch: {
    content: {
      handler(newVal: string, oldVal: string) {
        this.tinycontent = newVal;
      },
    },
  },
  mounted() {
    this.tinycontent = this.content;
    this.tencentCloudCosConfig();
  },
  methods: {
    /**
     * 内容变更
     */
    handleContentChange() {
      this.$emit("handleChange", this.editor.getContent());
    },

    /**
     * 单图片上传
     */
    selectLocalSingleImage() {
      this.insertTagName = "image";
      this.mime = {
        "image/jpeg": "jpg",
        "image/jpg": "jpg",
        "image/png": "png",
        "image/gif": "gif",
      };

      this.accept = "image/*";
      this.uploadTip = "支持jpg/jpeg/png/gif 文件，最大上传2M";
      this.uploadDialogTitle = "图片上传";
      this.centerDialogVisible = true;
    },
    /**
     * 单视频文件上传
     */
    selectLocalVideo() {
      this.insertTagName = "video";
      this.mime = {
        "video/mp4": "mp4",
        "video/mp3": "mp3",
      };

      this.accept = "video/*";
      this.uploadTip = "支持mp4/mp3 文件，最大上传50M";
      this.uploadDialogTitle = "视频上传";
      this.centerDialogVisible = true;
    },

    /**
     * 上传前检查
     * @param file
     */
    handleBeforeUpload(file: any) {
      return new Promise(async (resolve, reject) => {
        if (!this.mime[file.type]) {
          ElMessage.error(`请上传正确的文件类型`);
          reject(`请上传正确的文件类型`);
          return false;
        }

        let fileSize = file.size / 1024 / 1024;
        let maxSize = 2;

        if (this.insertTagName == "video") {
          maxSize = 50;
        }

        if (fileSize > maxSize) {
          ElMessage.error(`请上传${maxSize}M内的文件`);
          reject(`请上传${maxSize}M内的文件`);
          return false;
        }

        resolve(true);
        return true;
      });
    },

    /**
     * 自定义上传文件的请求
     * @param event
     */
    async handleUpload(event: any) {
      console.log("handleUpload", event);
      this.uploading = true;
      this.process = 0;
      let file = event.file;

      if (!file) {
        ElMessage.error("请选择上传的文件！");
        return false;
      }

      const cos = new COS({
        SecretId: this.config.secretId,
        SecretKey: this.config.secretKey,
      });

      // config.filePath += `${new Date().getTime()}-${file.name}`;

      this.config.filePath += file.name;

      cos.putObject(
        {
          Bucket: this.config.bucket,
          Region: this.config.region,
          Key: this.config.filePath,
          Body: event.file,
          onProgress: (progressData) => {
            console.log("progressData", JSON.stringify(progressData));
            this.process = progressData.percent * 100;
          },
        },
        (err, data) => {
          console.log(err || data);
          if (err) {
            console.log("upload error", err);
            ElMessage.error("上传失败！" + err);
            return false;
          }

          console.log("url", "//" + data.Location);

          this.uploading = false;
          this.centerDialogVisible = false;
          let dom = this.editor.dom;

          this.editor.selection.setContent(
            dom.createHTML(this.insertTagName, {
              src: "//" + data.Location,
              style: "max-width: 100%;",
              controls: "controls",
              autoplay: "autoplay",
            })
          );

          this.$emit("handleChange", this.editor.getContent());
          ElMessage.success("上传成功！");
        }
      );
    },

    /**
     * 图片上传
     * @param blobInfo
     * @param succFun
     * @param failFun
     */
    async imagesUploadHandler(blobInfo: any, succFun: any, failFun: any) {
      return new Promise(async (resolve, reject) => {
        console.log("imagesUploadHandler", "blobInfo", blobInfo);
        this.selectLocalSingleImage();
        this.centerDialogVisible = false;
        let file = blobInfo.blob();

        await this.handleBeforeUpload(file);

        if (!file) {
          ElMessage.error("请选择上传的文件！");
          return false;
        }

        const cos = new COS({
          SecretId: this.config.secretId,
          SecretKey: this.config.secretKey,
        });

        // config.filePath += `${new Date().getTime()}-${file.name}`;

        this.config.filePath += file.name;

        cos.putObject(
          {
            Bucket: this.config.bucket,
            Region: this.config.region,
            Key: this.config.filePath,
            Body: file,
            onProgress: (progressData) => {
              console.log("progressData", JSON.stringify(progressData));
              this.process = progressData.percent * 100;
            },
          },
          (err, data) => {
            console.log(err || data);
            if (err) {
              console.log("upload error", err);
              ElMessage.error("上传失败！" + err);
              return false;
            }

            console.log("url", "//" + data.Location);

            succFun("//" + data.Location);
            resolve("//" + data.Location);
          }
        );
      });
    },

    /**
     * 获取腾讯云存储桶的配置信息
     */
    async tencentCloudCosConfig() {
      tencentCloudCosConfig({}).then((res: any) => {
        if (isNotEmpty(res)) {
            this.config = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        });
    },

    /**
     * 上传完成
     * @param response
     * @param file
     * @param fileList
     */
    handleSuccess(response: any, file: any, fileList: any) {
      console.log("success", response, file, fileList);
    },

    /**
     * 上传失败
     * @param error
     * @param file
     * @param fileList
     */
    handleError(error: any, file: any, fileList: any) {
      console.log("error", error, file, fileList);
    },
  },
});
</script>

<style lang="less" scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
