<!-- 选择课程 -->
<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingText"
    style="height: 100%"
  >
    <el-input
      v-if="showSerach"
      v-model="filterText"
      placeholder="请输入课程名称"
      style="padding: 10px"
    >
      <template #suffix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
    </el-input>
    <el-tree
      ref="treeData"
      class="flow-tree st-overflow-x st-overflow-y"
      :disabled="disabled"
      :style="styles"
      :data="listData"
      :props="propsRule"
      node-key="id"
      :highlight-current="highlightCurrent"
      :default-expanded-keys="defaultExpandedKeys"
      :auto-expand-parent="autoExpandParent"
      :show-checkbox="showCheckbox"
      :check-strictly="checkStrictly"
      :default-checked-keys="defaultCheckedKeys"
      :filter-node-method="filterNode"
      :expand-on-click-node="expandNoClickNode"
      :filterable="filterable"
      @node-click="handleNodeClick"
      @node-contextmenu="handleNodeContextmenu"
      @check="handleCheck"
    >
      <template v-slot="{ node }">
        <Folder style="width: 20px; height: 20px; margin-right: 5px" />
        <span>{{ node.label }}</span>
      </template>
      <template v-slot:empty>
        <el-empty description="没有数据！~"></el-empty>
      </template>
    </el-tree>
  </div>
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";

import { isNotEmpty } from "@/utils/helper";

import { getAuthColumnTree } from "@/api/shopColumn";

export default defineComponent({
  /**
   * 使用组件
   */
  components: {},
  name: "ShopColumnTree",

  /**
   * 接收父页面传递的值
   */
  emits: ["handleTreeChange"],

  props: {
    /**
     * @param Boolean showSerach 是否 显示搜索框 默认true
     * @param Boolean disabled 是否禁用 默认false
     * @param Object styles 样式
     * @param Boolean isChildrenIds 是否 获取子节点id 默认true 获取
     * @param Object parameCriteria 传递 展示列表 的条件
     * @param Boolean defaultPush 是否 显示 追加默认分类列表  true
     * @param Boolean isContextmenu 是否右键菜单 默认false
     */
    showSerach: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isChildrenIds: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    parameCriteria: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultPush: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    isContextmenu: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    /**
     * @param Boolean highlightCurrent 是否高亮 默认true
     * @param Array defaultExpandedKeys 默认展开的节点的 key 的数组
     * @param Boolean autoExpandParent 展开子节点的时候是否自动展开父节点 默认true
     * @param Boolean showCheckbox 是否是 多选 默认false 为单选
     * @param Boolean checkStrictly 在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false
     * @param Array defaultCheckedKeys 默认勾选的节点的 key 的数组
     * @param Boolean expandNoClickNode 是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
     * @param Boolean filterable 过滤所有树节点，过滤后的节点将被隐藏
     */
    highlightCurrent: {
      type: Boolean,
      default: true,
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    autoExpandParent: {
      type: Boolean,
      default: true,
    },
    showCheckbox: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    expandNoClickNode: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      loadingText: "加载中...",

      propsRule: {
        value: "id",
        label: "title",
        children: "children",
      },

      /**
       * 课程列表
       */
      idsData: [] as any,
      listData: [] as any,
      defaultType: [
        {
          id: "0",
          title: "所有随堂考",
          pid: 0,
          level: 0,
          children: [],
        },
        {
          id: -1,
          title: "其他",
          pid: 0,
          level: 0,
          children: [],
        },
      ],
      filterText: "",
      isExpandAll: false,
      refreshTable: true,
    };
  },
  created() {
    this.initTreeFun();
  },
  watch: {
    filterText(val) {
      if (this.$refs.treeData) {
        (this.$refs.treeData as any).filter(val);
      }
    },
  },
  methods: {
    initTreeFun() {
      this.searchTypeWhere();
    },

    /**
     * 获取左侧课程
     */
    searchTypeWhere() {
      this.listData = [];
      if (this.defaultPush) {
        this.listData = [...this.listData, ...this.defaultType];
      } else {
        this.listData = [];
      }

      this.getAuthColumnTree(this.parameCriteria);
    },

    /**
     * 获取课程
     */
    async getAuthColumnTree(parameter: any) {
      getAuthColumnTree(parameter).then((res: any) => {
        if (isNotEmpty(res)) {
          this.listData = [...this.listData, ...res.data];
        }

        this.loading = false;
        this.handelChangeDepartment(this.defaultCheckedKeys);
      });
    },

    /**
     * 变更选中内容
     */
    handelChangeDepartment(defaultCheckedKeys: Array<any>) {
      if (defaultCheckedKeys.length <= 0) {
        return false;
      }

      this.$nextTick(() => {
        /**
         * 多选和单选的选中渲染
         */
        if (this.$refs.treeData) {
          if (this.showCheckbox == false) {
            (this.$refs.treeData as any).setCurrentKey(defaultCheckedKeys[0]);
          } else {
            (this.$refs.treeData as any).setCheckedKeys(defaultCheckedKeys);
          }
        }
      });
    },

    /**
     * 右键点击
     */
    handleNodeContextmenu(node: any, data: any) {
      if (this.showCheckbox || !this.isContextmenu) {
        return false;
      }

      this.handelChangeDepartment([data.id]);
      this.$emit("handleTreeChange", data.id);
    },

    /**
     * 点击节点复选框之后触发
     */
    handleCheck(node: any, checkedData: any) {
      if (!this.showCheckbox) {
        return false;
      }

      let idsData: any = checkedData.checkedKeys;

      idsData.sort(function (a: any, b: any) {
        return a - b;
      });

      let typeIds = idsData.join(",");

      this.$emit("handleTreeChange", typeIds);
    },

    /**
     * 当节点被点击的时候触发
     * @param data 被点击的节点数据
     */
    handleNodeClick(data: any) {
      if (this.showCheckbox) {
        return false;
      }

      this.idsData = [data.id];

      /**
       * 如果需要包含子节点的 ID，并且当前节点有子节点
       */
      if (
        this.isChildrenIds === true &&
        data.children &&
        data.children.length > 0
      ) {
        // 递归获取子课程的 ID
        this.handleChangeChildren(data.children, true);
      }

      let typeIds = this.idsData.join(",");

      this.$emit("handleTreeChange", typeIds);
    },

    /**
     * 点击课程获取id，处理多个课程及其子分类的选中状态
     * @param data 当前处理的课程数据
     * @param isChecked 当前分类是否被选中
     */
    handleChangeChildren(data: any[], isChecked: boolean) {
      if (!data || data.length === 0) {
        return;
      }

      data.forEach((item: any) => {
        const index = this.idsData.indexOf(item.id);

        /**
         * 根据选中状态决定是添加还是删除id
         */
        if (isChecked && index === -1) {
          this.idsData.push(item.id);
        } else if (!isChecked && index !== -1) {
          this.idsData.splice(index, 1);
        }

        /**
         * 如果存在子节点且当前操作导致状态变化（添加或删除），则递归处理子节点
         */
        const shouldRecurse = isChecked !== (index !== -1);

        if (shouldRecurse && item.children && item.children.length > 0) {
          this.handleChangeChildren(item.children, isChecked);
        }
      });
    },

    /**
     * 全部展开/折叠
     */
    toggleRowExpansion() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    /**
     * 筛选结点
     */
    filterNode(value: any, data: any) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
  },
});
</script>
<style lang="less" scoped>
@import "../styles/less/flow-tree.less";
</style>
