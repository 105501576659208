<!-- 选择部门 -->
<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingText"
    style="height: 100%"
  >
    <el-input
      v-if="showSerach"
      v-model="filterText"
      :disabled="filterDisabled"
      placeholder="请输入部门名称"
      style="padding: 10px"
    >
      <template #suffix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
    </el-input>
    <el-tree
      ref="treeData"
      class="flow-tree st-overflow-x st-overflow-y"
      :disabled="disabled"
      :style="styles"
      :data="listData"
      :props="propsRule"
      node-key="department_id"
      :highlight-current="highlightCurrent"
      :default-expanded-keys="defaultExpandedKeys"
      :auto-expand-parent="autoExpandParent"
      :show-checkbox="showCheckbox"
      :check-strictly="checkStrictly"
      :default-checked-keys="defaultCheckedKeys"
      :filter-node-method="filterNode"
      :expand-on-click-node="expandNoClickNode"
      :filterable="filterable"
      @node-click="handleNodeClick"
      @node-contextmenu="handleNodeContextmenu"
      @check="handleCheck"
    >
      <template v-slot="{ node, data }">
        <Folder style="width: 20px; height: 20px; margin-right: 5px" />
        <span>
          <el-tooltip placement="top" effect="dark">
            <template #content>
              ID: {{ data.id }}
              <br />
              DID: {{ data.department_id }}
              <br />
              <span v-if="data.department_id == -1">
                {{ data.department_name }}
              </span>
              <span v-else>
                <OpenData :type="`departmentName`" :openid="node.label" />
              </span>
            </template>
            <span v-if="data.department_id == -1">
              {{ data.department_name }}
            </span>
            <span v-else>
              <OpenData :type="`departmentName`" :openid="node.label" />
            </span>
          </el-tooltip>
        </span>
      </template>
      <template v-slot:empty>
        <el-empty description="没有数据！~"></el-empty>
      </template>
    </el-tree>
  </div>
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";

import { OpenData } from "@/components";
import { isNotEmpty } from "@/utils/helper";

import { initGetAgentConfig } from "@/utils/getAgerentConfig";

import { getDepartmentList } from "@/api/department";

export default defineComponent({
  /**
   * 使用组件
   */
  components: {
    OpenData,
  },
  name: "ExamTypeTree",

  /**
   * 接收父页面传递的值
   */
  emits: ["handleTreeChange"],

  props: {
    /**
     * @param Boolean showSerach 是否 显示搜索框 默认true
     * @param Boolean disabled 是否禁用 默认false
     * @param Object styles 样式
     * @param Boolean isChildrenIds 是否 获取子节点id 默认true 获取
     * @param Boolean defaultPush 是否 显示 追加默认分类列表  true
     * @param Boolean isContextmenu 是否右键菜单 默认false
     */
    showSerach: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isChildrenIds: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    defaultPush: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    isContextmenu: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    /**
     * @param Boolean highlightCurrent 是否高亮 默认true
     * @param Array defaultExpandedKeys 默认展开的节点的 key 的数组
     * @param Boolean autoExpandParent 展开子节点的时候是否自动展开父节点 默认true
     * @param Boolean showCheckbox 是否是 多选 默认false 为单选
     * @param Boolean checkStrictly 在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false
     * @param Array defaultCheckedKeys 默认勾选的节点的 key 的数组
     * @param Boolean expandNoClickNode 是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
     * @param Boolean filterable 过滤所有树节点，过滤后的节点将被隐藏
     */
    highlightCurrent: {
      type: Boolean,
      default: true,
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    autoExpandParent: {
      type: Boolean,
      default: true,
    },
    showCheckbox: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    expandNoClickNode: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      loadingText: "加载中...",

      propsRule: {
        value: "department_id",
        label: "department_name",
        children: "children",
      },

      /**
       * 部门列表
       */
      idsData: [] as any,
      listData: [] as any,
      defaultPushList: [
        {
          id: 0,
          department_id: -1,
          department_name: "所有部门",
          parentid: 0,
          children: [],
        },
      ],
      displayedNodes: [] as any,
      filterText: "",
      filterDisabled: false,
      isExpandAll: false,
      refreshTable: true,
    };
  },
  created() {
    this.initTreeFun();
  },
  watch: {
    filterText(val) {
      console.log("filterText", val);
      if (this.filterDisabled) {
        return false;
      }

      this.filterDisabled = true;
      this.loading = true;

      if (/^\d+$/.test(val) === false || val == "") {
        this.searchTypeWhere();
        return false;
      }

      if (this.$refs.treeData) {
        (this.$refs.treeData as any).filter(val);
        this.loading = false;
        this.filterDisabled = false;
      }
    },
  },
  methods: {
    initTreeFun() {
      this.searchTypeWhere();
      initGetAgentConfig({ url: encodeURI(window.location.href) });
    },

    /**
     * 获取左侧部门
     */
    searchTypeWhere() {
      this.listData = [];

      if (this.defaultPush) {
        this.listData = [...this.listData, ...this.defaultPushList];
      } else {
        this.listData = [];
      }

      let params = {
        searchName: this.filterText,
        isTree: "tree",
      };

      this.getDepartmentList(params);
    },

    /**
     * 获取部门
     */
    async getDepartmentList(parameter: any) {
      getDepartmentList(parameter).then((res: any) => {
        if (isNotEmpty(res)) {
          this.loadingText = "解析数据中...";
          this.listData = [...this.listData, ...res.data];
        }

        this.loading = false;
        this.filterDisabled = false;
        this.handelChangeDepartment(this.defaultCheckedKeys);
      });
    },

    /**
     * 变更选中内容
     */
    handelChangeDepartment(defaultCheckedKeys: Array<any>) {
      if (defaultCheckedKeys.length <= 0) {
        return false;
      }

      this.$nextTick(() => {
        /**
         * 多选和单选的选中渲染
         */
        if (this.$refs.treeData) {
          if (this.showCheckbox == false) {
            (this.$refs.treeData as any).setCurrentKey(defaultCheckedKeys[0]);
          } else {
            (this.$refs.treeData as any).setCheckedKeys(defaultCheckedKeys);
          }
        }
      });
    },

    /**
     * 右键点击
     */
    handleNodeContextmenu(node: any, data: any) {
      if (this.showCheckbox || !this.isContextmenu) {
        return false;
      }

      this.handelChangeDepartment([data.department_id]);
      console.log("departmentIds: ", data.department_id);
      this.$emit("handleTreeChange", data.department_id);
    },

    /**
     * 点击节点复选框之后触发
     */
    handleCheck(node: any, checkedData: any) {
      if (!this.showCheckbox) {
        return false;
      }

      let idsData: any = checkedData.checkedNodes.map(
        (row: any) => row.department_id
      );

      idsData.sort(function (a: any, b: any) {
        return a - b;
      });

      let departmentIds = idsData.join(",");

      console.log("departmentIds: ", departmentIds);
      this.$emit("handleTreeChange", departmentIds);
    },

    /**
     * 当节点被点击的时候触发
     * @param data 被点击的节点数据
     */
    handleNodeClick(data: any) {
      console.log("handleNodeClick", data);

      if (this.showCheckbox) {
        return false;
      }

      this.idsData = [data.department_id];

      /**
       * 如果需要包含子节点的 ID，并且当前节点有子节点
       */
      if (
        this.isChildrenIds === true &&
        data.children &&
        data.children.length > 0
      ) {
        /**
         * 递归获取子部门的 ID
         */
        this.handleChangeChildren(data.children, true);
      }

      let departmentIds = this.idsData.join(",");

      console.log("departmentIds: ", departmentIds);
      this.$emit("handleTreeChange", departmentIds);
    },

    /**
     * 点击部门获取id，处理多个部门及其子分类的选中状态
     * @param data 当前处理的部门数据
     * @param isChecked 当前分类是否被选中
     */
    handleChangeChildren(data: any[], isChecked: boolean) {
      if (!data || data.length === 0) {
        return;
      }

      data.forEach((item: any) => {
        const index = this.idsData.indexOf(item.department_id);

        /**
         * 根据选中状态决定是添加还是删除id
         */
        if (isChecked && index === -1) {
          this.idsData.push(item.department_id);
        } else if (!isChecked && index !== -1) {
          this.idsData.splice(index, 1);
        }

        /**
         * 如果存在子节点且当前操作导致状态变化（添加或删除），则递归处理子节点
         */
        const shouldRecurse = isChecked !== (index !== -1);

        if (shouldRecurse && item.children && item.children.length > 0) {
          this.handleChangeChildren(item.children, isChecked);
        }
      });
    },

    /**
     * 全部展开/折叠
     */
    toggleRowExpansion() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    /**
     * 筛选结点
     */
    filterNode(value: any, data: any) {
      if (!value) return true;
      return data.department_name.indexOf(value) !== -1;
    },
  },
});
</script>
<style lang="less" scoped>
@import "../../styles/less/flow-tree.less";
.el-tooltip__trigger {
  min-width: 50px;
}
</style>
