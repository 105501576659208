import { useAxios } from "@/utils/useAxios";

const request = useAxios();

/**
 * 通讯录组件配置获取
 */
export const getAgentConfig = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/home/agent-config", params });

  return res && res.data;
};

/**
 * 获取企业试用日期
 */
export const getTrialDate = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/home/trial-date", params });

  return res && res.data;
};

/**
 * 首页 企业概况
 */
export const getCount = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/home/get-count", params });

  return res && res.data;
};

/**
 * 首页 智能消息助手
 */
export const getMessage = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/home/get-message", params });

  return res && res.data;
};

/**
 * 首页 最近一次企业考试的id
 */
export const getLastExamId = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/home/get-last-exam-id", params });

  return res && res.data;
};
