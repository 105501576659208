<!-- 考试列表 -->
<template>
  <el-dialog
    v-model="dialogDTreeUListShow"
    title="选择成员"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    width="70%"
    style="overflow-y: auto"
    :before-close="onCancelDTreeUList"
  >
    <div
      class="st-overflow-x st-overflow-y main-div"
      v-loading="loading"
      element-loading-text="Loading..."
      style="padding: 10px 20px; width: calc(100% - 40px)"
    >
      <el-row class="mt-4 flex-justify-betwwen" style="min-width: 700px">
        <div class="department-content-div class-div-left">
          <!-- 部门树 -->
          <DepartmentTree
            @handleTreeChange="handleChangeDepartmentFunc"
            :showCheckbox="false"
            :expandNoClickNode="true"
            :isContextmenu="true"
            :defaultPush="true"
          />
        </div>

        <div class="department-content-div class-div-center st-overflow-x">
          <el-input
            v-model="searchName"
            placeholder="请输入用户名称"
            style="padding: 10px"
            @keyup.enter="searchWhereUserFunc"
          >
            <template #suffix>
              <el-icon
                class="el-input__icon"
                @click="searchWhereUserFunc"
                style="cursor: pointer"
              >
                <search />
              </el-icon>
            </template>
          </el-input>
          <div class="st-overflow-x st-overflow-y">
            <el-table
              ref="multipleTable"
              class="table-list"
              :data="userLists"
              highlight-current-row
              :show-header="false"
              :height="tableHeight"
              :max-height="tableHeight"
              border
              :row-style="{ height: 60 + 'px !important' }"
              :header-cell-style="{
                background: '#f7f7f7',
                height: 60 + 'px !important',
              }"
              @select="handleSelectDUTree"
              @row-click="handleRowClick"
            >
              <el-table-column type="selection" width="40" fixed="left" />
              <el-table-column type="index" :index="indexMethod" />
              <el-table-column prop="avatar" width="60" align="center">
                <template v-slot="scope">
                  <el-tooltip placement="top" effect="dark">
                    <template #content>
                      <el-avatar size="large" :src="scope.row.avatar" />
                    </template>
                    <el-avatar size="small" :src="scope.row.avatar" />
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="username">
                <template v-slot="scope">
                  <span>
                    <OpenData :type="`userName`" :openid="scope.row.username" />
                  </span>
                </template>
              </el-table-column>

              <template v-slot:empty>
                <el-empty description="没有数据！~"></el-empty>
              </template>
            </el-table>
          </div>

          <!-- 分页 -->
          <div class="pagination-container" style="padding: 16px 16px 0px">
            <el-pagination
              v-show="pageCount > 0"
              v-model:total="total"
              v-model:page-size="size"
              v-model:current-page="current"
              :page-sizes="[10, 20, 30, 50, 100, 200]"
              :hide-on-single-page="false"
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>

        <div class="department-content-div class-div-right">
          <div
            style="
              height: 52px;
              align-items: center;
              display: flex;
              padding-left: 30px;
              width: calc(100% - 40px);
              justify-content: space-between;
            "
          >
            <div>已选成员列表</div>

            <el-button type="primary" @click="onEmptyDTreeUList">
              清空
            </el-button>
          </div>
          <el-table
            class="table-list"
            :data="selectUserList"
            highlight-current-row
            :show-header="false"
            :height="tableHeight"
            :max-height="tableHeight"
            border
            :row-style="{ height: 60 + 'px !important' }"
            :header-cell-style="{
              background: '#f7f7f7',
              height: 60 + 'px !important',
            }"
          >
            <el-table-column type="index" :index="indexMethod" fixed="left" />
            <el-table-column prop="avatar" width="60" align="center">
              <template v-slot="scope">
                <el-tooltip placement="top" effect="dark">
                  <template #content>
                    <el-avatar size="large" :src="scope.row.avatar" />
                  </template>
                  <el-avatar size="small" :src="scope.row.avatar" />
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="userid">
              <template v-slot="scope">
                <span>
                  <OpenData :type="`userName`" :openid="scope.row.userid" />
                </span>
              </template>
            </el-table-column>
            <el-table-column width="80">
              <template v-slot="scope">
                <el-button @click="handleDeleteDUTree(scope.row)">
                  <el-icon><CloseBold /></el-icon>
                </el-button>
              </template>
            </el-table-column>
            <template v-slot:empty>
              <el-empty description="没有数据！~"></el-empty>
            </template>
          </el-table>
        </div>
      </el-row>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="onSubmitDTreeUList"> 保存 </el-button>
        <el-button @click="onCancelDTreeUList">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

import { OpenData, DepartmentTree } from "@/components";
import { initGetAgentConfig } from "@/utils/getAgerentConfig";
import { isNotEmpty } from "@/utils/helper";

import { getUserList } from "@/api/user";

export default defineComponent({
  /**
   * 使用组件
   */
  components: {
    OpenData,
    DepartmentTree,
  },

  name: "DepartmentTreeAndUserList",

  emits: ["openDTreeUList", "userArr", "handleChangeDTreeUListFunc"],

  props: {
    openDTreeUList: {
      type: String,
      default: () => {
        return "dialogDTreeUListView";
      },
    },
    userArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      leftLayout: false,
      tableHeight: "calc(100vh - 400px)",

      /**
       * 全屏遮蔽
       */
      loading: false,
      dialogDTreeUListShow: false,

      /**
       * 分页数据
       * 总条数 total
       * 总页数 pageCount
       * 当前页 current
       * 每页显示条数 size
       */
      userLists: [] as any,
      total: 0,
      pageCount: 0,
      current: 1,
      size: 20,

      /**
       * 查询条件
       */
      searchName: "",
      departmentIds: "",
      searchUserWhere: {
        searchName: "",
        departmentIds: "",
      },

      selectUserList: [] as any,
    };
  },
  methods: {
    /**
     * 初始化
     */
    initFunDTreeUList() {
      this.setDUTreeShowTrueOrFalse(true);
      this.selectUserList = this.userArr;
      this.userLists = [] as any;
      this.total = 0;
      this.pageCount = 0;
      this.current = 1;
      this.dialogDTreeUListShow = true;
      initGetAgentConfig({ url: encodeURI(window.location.href) });
      this.setDUTreeShowTrueOrFalse(false);
    },

    /**
     * 点击部门
     */
    handleChangeDepartmentFunc(departmentIds: any) {
      this.departmentIds = departmentIds;

      if (this.departmentIds) {
        this.searchWhereUserFunc();
      }
    },

    /**
     * 搜索条件组合
     */
    searchWhereUserFunc() {
      this.setDUTreeShowTrueOrFalse(true);
      /**
       * 初始化参数
       */
      this.userLists = [];
      this.searchUserWhere.searchName = this.searchName;
      this.searchUserWhere.departmentIds = this.departmentIds;

      let params = {
        page: this.current,
        limit: this.size,
        searchName: this.searchUserWhere.searchName,
        departmentIds: this.searchUserWhere.departmentIds,
      };

      this.initgetUserList(params);
    },

    /**
     * 获取成员接口
     */
    async initgetUserList(parameter: any) {
      getUserList(parameter).then((res: any) => {
        if (isNotEmpty(res)) {
          this.userLists = res.data.list;
          this.pageCount = res.data.pageCount;
          this.total = res.data.total;

          this.$nextTick(() => {
            this.userLists.forEach((row: any) => {
              let list = this.selectUserList;

              if (
                list.findIndex((item: any) => item.userid == row.account) !==
                  -1 &&
                this.$refs.multipleTable
              ) {
                (this.$refs.multipleTable as any).toggleRowSelection(row, true);
              }
            });
          });
        }

        this.setDUTreeShowTrueOrFalse(false);
      });
    },

    /**
     * 中间数据 点击多选框
     */
    handleSelectDUTree(selection: any, row: any) {
      let checkedIndex = this.selectUserList.findIndex(
        (tiem: any) => tiem.userid == row.account
      );

      /**
       * 用户
       */
      if (-1 == checkedIndex) {
        this.selectUserList.unshift({
          avatar: row.avatar,
          userid: row.account,
        });
      } else {
        this.selectUserList.splice(checkedIndex, 1);

        if (this.$refs.multipleTable) {
          (this.$refs.multipleTable as any).toggleRowSelection(row, false);
        }
      }

      this.selectUserList.forEach((rows: any) => {
        if (rows.userid == row.account && this.$refs.multipleTable) {
          (this.$refs.multipleTable as any).toggleRowSelection(row, true);
        }
      });
    },
    /**
     * 点击行选中
     */
    handleRowClick(row: any, column: any, event: any) {
      /**
       * 阻止点击多选框时触发
       */
      if (
        event.target.tagName.toLowerCase() !== "input" ||
        event.target.type !== "checkbox"
      ) {
        this.handleSelectDUTree(null, row);
      }
    },

    /**
     * 右侧数据 点击删除
     */
    handleDeleteDUTree(element: any) {
      /**
       * 用户
       */
      let index = this.selectUserList.findIndex(
        (tiem: any) => tiem.userid == element.userid
      );

      if (-1 < index) {
        this.userLists.forEach((rows: any) => {
          if (
            this.selectUserList[index].userid == rows.account &&
            this.$refs.multipleTable
          ) {
            (this.$refs.multipleTable as any).toggleRowSelection(rows, false);
          }
        });

        this.selectUserList.splice(index, 1);
      }
    },

    /**
     * 右侧数据 点击清空，清空数据
     */
    onEmptyDTreeUList() {
      this.selectUserList = [];
      this.userLists.forEach((rows: any) => {
        if (this.$refs.multipleTable) {
          (this.$refs.multipleTable as any).toggleRowSelection(rows, false);
        }
      });

      this.setDUTreeShowTrueOrFalse(true);
      this.setDUTreeShowTrueOrFalse(false);
    },

    /**
     * 点击保存使用父级页面方法关闭当前页
     */
    onSubmitDTreeUList() {
      this.$emit("handleChangeDTreeUListFunc", this.selectUserList);
      this.$emit("openDTreeUList", "");

      this.setDUTreeShowTrueOrFalse(true);

      setTimeout(() => {
        this.dialogDTreeUListShow = false;
      }, 1000);
    },

    /**
     * 点击取消，使用父级页面方法关闭当前页
     */
    onCancelDTreeUList() {
      this.$emit("openDTreeUList", "");
      this.dialogDTreeUListShow = false;
    },

    /**
     * 显示还是隐藏
     * @param bools true 显示限制，false 不显示不限制
     * @param msg 提示消息
     */
    setDUTreeShowTrueOrFalse(bools = true, msg = "") {
      if (bools) {
        this.loading = bools;
      } else {
        /**
         * 消息提示
         */
        if (msg) {
          ElMessage.error(msg);
        }
        /**
         * 隐藏全局遮蔽
         */
        setTimeout(() => {
          this.loading = bools;
        }, 500);
      }
    },

    /**
     * 设置分页限制条数
     */
    handleSizeChange(val: number) {
      if (this.current * val > this.total) {
        this.current = 1;
      }

      this.size = val;
      this.searchWhereUserFunc();
    },

    /**
     * 分页当前页跳转
     */
    handleCurrentChange(val: number) {
      this.current = val;
      this.searchWhereUserFunc();
    },
    /**
     * 每页序号延续
     */
    indexMethod(index: any) {
      /**
       * table的index属性为方法时 序号从0开始
       * 序号加1从1开始 + 前面页数的条数总数
       * 前面页数 : 当前页数-1,乘以每页行数
       */
      return index + (this.current - 1) * this.size + 1;
    },
  },
});
</script>
<style>
.el-dialog__body {
  padding: 0px var(--el-dialog-padding-primary) var(--el-dialog-padding-primary);
}
.el-dialog__footer {
  border-top: 1px solid #ebebeb;
}
</style>
<style lang="less" scoped>
@import "../../styles/less/flow-tree.less";

:deep(.main-div) {
  min-width: 1200px;
}
:deep(.department-content-div) {
  height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
  min-width: 33%;
  max-width: 33%;
  border: 1px solid #dcdfe6;
}
</style>
