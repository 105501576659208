import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["element-loading-text"]
const _hoisted_2 = { class: "custom-tree-node" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "custom-tree-node-left-text" }
const _hoisted_5 = {
  key: 0,
  class: "custom-tree-node-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search = _resolveComponent("search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_Folder = _resolveComponent("Folder")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_QuestionCategoryEdit = _resolveComponent("QuestionCategoryEdit")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      "element-loading-text": _ctx.loadingText,
      style: {"height":"100%"}
    }, [
      (_ctx.showSerach)
        ? (_openBlock(), _createBlock(_component_el_input, {
            key: 0,
            modelValue: _ctx.filterText,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
            placeholder: "请输入题库分类名称",
            style: {"padding":"10px"}
          }, {
            suffix: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "el-input__icon" }, {
                default: _withCtx(() => [
                  _createVNode(_component_search)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_tree, {
        ref: "treeData",
        class: "flow-tree st-overflow-x st-overflow-y",
        style: _normalizeStyle(_ctx.styles),
        data: _ctx.listData,
        props: _ctx.propsRule,
        "node-key": "id",
        disabled: _ctx.disabled,
        "highlight-current": _ctx.highlightCurrent,
        "filter-node-method": _ctx.filterNode,
        "expand-on-click-node": _ctx.expandNoClickNode,
        "check-strictly": _ctx.checkStrictly,
        "default-checked-keys": _ctx.defaultCheckedKeys,
        "show-checkbox": _ctx.showCheckbox,
        onCheck: _ctx.handleCheck,
        onNodeClick: _ctx.handleNodeClick,
        onNodeContextmenu: _ctx.handleNodeContextmenu
      }, {
        default: _withCtx(({ node, data }) => [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("span", {
              class: "custom-tree-node-left",
              title: node.label + `(` + data.total + `)`,
              style: _normalizeStyle(
              _ctx.isOptionMenu &&
              ((data.id > 1 && data.shop_id > 1) || data.id == 2)
                ? { maxWidth: 'calc(100% - 80px)' }
                : { maxWidth: '100%' }
            )
            }, [
              _createVNode(_component_Folder, { style: {"min-width":"20px","min-height":"20px","max-width":"20px","max-height":"20px","margin-right":"5px"} }),
              _createElementVNode("span", _hoisted_4, [
                _createTextVNode(_toDisplayString(node.label) + " ", 1),
                _createElementVNode("span", null, "(" + _toDisplayString(data.total) + ")", 1)
              ])
            ], 12, _hoisted_3),
            (
              _ctx.isOptionMenu &&
              ((data.id > 1 && data.shop_id > 1) || data.id == 2)
            )
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createVNode(_component_el_icon, {
                    style: {"margin-left":"10px"},
                    onClick: ($event: any) => (_ctx.handleOpenPageFun(data, 'save', 'add')),
                    title: "添加"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Plus, { style: {"color":"rgb(39, 99, 220)"} })
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  (data.id > 1 && data.shop_id > 1)
                    ? (_openBlock(), _createBlock(_component_el_icon, {
                        key: 0,
                        style: {"margin-left":"10px"},
                        onClick: ($event: any) => (_ctx.handleOpenPageFun(data, 'save', 'edit')),
                        title: "修改"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Edit, { style: {"color":"rgb(39, 99, 220)"} })
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true),
                  (data.id > 1 && data.shop_id > 1)
                    ? (_openBlock(), _createBlock(_component_el_icon, {
                        key: 1,
                        style: {"margin":"0 15px 0 10px"},
                        onClick: ($event: any) => (_ctx.handleDelClick(node, data)),
                        title: "删除"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Delete, { style: {"color":"rgb(245, 108, 108)"} })
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        empty: _withCtx(() => [
          _createVNode(_component_el_empty, { description: "没有数据！~" })
        ]),
        _: 1
      }, 8, ["style", "data", "props", "disabled", "highlight-current", "filter-node-method", "expand-on-click-node", "check-strictly", "default-checked-keys", "show-checkbox", "onCheck", "onNodeClick", "onNodeContextmenu"])
    ], 8, _hoisted_1)), [
      [_directive_loading, _ctx.loading]
    ]),
    (_ctx.openPage == 'save')
      ? (_openBlock(), _createBlock(_component_QuestionCategoryEdit, {
          key: 0,
          ref: "dialogEditView",
          categoryLists: _ctx.listData,
          onSearchWhere: _ctx.searchCateWhere
        }, null, 8, ["categoryLists", "onSearchWhere"]))
      : _createCommentVNode("", true)
  ], 64))
}