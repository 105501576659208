import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { AppLayout } from "@/layout";

const routes: Array<RouteRecordRaw> = [
  /**
   * 默认访问页面 登录页
   */
  {
    path: "/",
    name: "index",
    redirect: "/login/login",
  },
  /**
   * 登录页
   */
  {
    path: "/login",
    name: "login",
    redirect: "/login/login",
    meta: { title: "登录页", hidden: true },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/LoginView.vue"),
        meta: { title: "登录页", hidden: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login-old",
    redirect: "/login/login-old",
    meta: { title: "登录页", hidden: true },
    children: [
      {
        path: "login-old",
        name: "OldLogin",
        component: () => import("@/views/OldLoginView.vue"),
        meta: { title: "登录页", hidden: true },
      },
    ],
  },
  /**
   * 帮助中心
   */
  {
    path: "/help",
    name: "help",
    redirect: "/help/index",
    meta: { title: "帮助中心", hidden: true },
    children: [
      {
        path: "index",
        name: "Help",
        component: () => import("@/views/HelpView.vue"),
        meta: { title: "帮助中心", hidden: true },
      },
    ],
  },
  /**
   * 概况
   */
  {
    path: "/home",
    name: "home",
    redirect: "/home/index",
    component: AppLayout,
    meta: {
      title: "首页概况",
      icon: "Monitor",
    },
    children: [
      {
        path: "index",
        name: "Home",
        component: () => import("@/views/HomeView.vue"),
        meta: { title: "首页概况", hidden: true },
      },
    ],
  },
  {
    path: "/questions",
    name: "questions",
    redirect: "/questions/list",
    component: AppLayout,
    meta: {
      title: "题库管理",
      icon: "MessageBox",
    },
    children: [
      {
        path: "import-excel-file",
        name: "importExcelFile",
        component: () => import("@/views/Questions/importExcelFileView.vue"),
        meta: { title: "题目导入", hidden: false },
      },
      {
        path: "list",
        name: "QuestionsList",
        component: () => import("@/views/Questions/QuestionsListView.vue"),
        meta: { title: "题目管理", hidden: false },
      },
    ],
  },
  {
    path: "/exam",
    name: "exam",
    redirect: "/exam/list",
    component: AppLayout,
    meta: {
      title: "考试管理",
      icon: "Memo",
    },
    children: [
      {
        path: "list",
        name: "ExamList",
        component: () => import("@/views/Exam/ExamListView.vue"),
        meta: { title: "考试管理", hidden: false },
      },
    ],
  },
  {
    path: "/show-print-exam",
    name: "show-print-exam",
    redirect: "/show-print-exam/index",
    meta: { title: "查看打印页", hidden: true },
    children: [
      {
        path: "index",
        name: "ShowPrintExam",
        component: () =>
          import("@/views/DataCenter/ExamQuery/ShowPrintExamView.vue"),
        meta: { title: "查看打印页", hidden: true },
      },
    ],
  },
  {
    path: "/data-center",
    name: "data-center",
    redirect: "/data-center/ranking",
    component: AppLayout,
    meta: {
      title: "数据中心",
      icon: "DataAnalysis",
    },
    children: [
      {
        path: "ranking",
        name: "Ranking",
        component: () => import("@/views/DataCenter/Ranking/RankingView.vue"),
        meta: { title: "排行榜", hidden: false },
      },
      {
        path: "exam-query",
        name: "ExamQueryList",
        component: () =>
          import("@/views/DataCenter/ExamQuery/ExamListView.vue"),
        meta: { title: "考试查询", hidden: false },
      },
      {
        path: "user-employee-list",
        name: "UserEmployeeList",
        component: () => import("@/views/User/UserEmployeeListView.vue"),
        meta: { title: "员工档案", hidden: false },
      },
      {
        path: "course-query",
        name: "CourseQueryList",
        component: () =>
          import("@/views/DataCenter/CourseQuery/CourseListView.vue"),
        meta: { title: "课程查询", hidden: false },
      },
    ],
  },

  /**
   * 培训计划管理
   */
  {
    path: "/study-plan",
    name: "study-plan",
    redirect: "/study-plan/index",
    component: AppLayout,
    meta: {
      title: "培训计划",
      icon: "MessageBox",
    },
    children: [
      {
        path: "index",
        name: "Plan",
        component: () => import("@/views/StudyPlan/PlanView.vue"),
        meta: { title: "计划列表", hidden: false },
      },
    ],
  },

  /**
   * 企业微信管理
   */
  {
    path: "/wecom",
    name: "wecom",
    redirect: "/wecom/index",
    component: AppLayout,
    meta: {
      title: "企业微信管理",
      icon: "MessageBox",
    },
    children: [
      {
        path: "index",
        name: "Contacts",
        component: () => import("@/views/Wecom/Contacts/ContactsView.vue"),
        meta: { title: "通讯录", hidden: false },
      },
      {
        path: "label",
        name: "Label",
        component: () => import("@/views/Wecom/Label/LabelView.vue"),
        meta: { title: "部门标签", hidden: false },
      },
      {
        path: "license",
        name: "License",
        component: () => import("@/views/Wecom/License/LicenseView.vue"),
        meta: { title: "许可证", hidden: false },
      },
    ],
  },

  /**
   * 智能消息助手
   */
  {
    path: "/message",
    name: "message",
    redirect: "/message/index",
    component: AppLayout,
    meta: {
      title: "智能消息助手",
      icon: "MessageBox",
    },
    children: [
      {
        path: "index",
        name: "message",
        component: () => import("@/views/Message/IndexView.vue"),
        meta: { title: "消息列表", hidden: false },
      },
    ],
  },
  /**
   * 下载中心
   */
  {
    path: "/downloads",
    name: "downloads",
    redirect: "/downloads/index",
    meta: { title: "下载中心", hidden: true },
    children: [
      {
        path: "index",
        name: "Downloads",
        component: () => import("@/views/Download/DownloadView.vue"),
        meta: { title: "下载中心", hidden: false },
      },
    ],
  },
  {
    path: "/download",
    name: "download",
    redirect: "/download/index",
    component: AppLayout,
    meta: {
      title: "下载中心",
      icon: "Files",
    },
    children: [
      {
        path: "index",
        name: "Download",
        component: () => import("@/views/Download/DownloadView.vue"),
        meta: { title: "下载中心", hidden: false },
      },
    ],
  },
  /**
   * 回收站
   */
  {
    path: "/recycle",
    name: "recycle",
    redirect: "/recycle/index",
    component: AppLayout,
    meta: {
      title: "回收站管理",
      icon: "Delete",
    },
    children: [
      {
        path: "index",
        name: "RecycleIndex",
        component: () => import("@/views/Recycle/IndexView.vue"),
        meta: { title: "回收站", hidden: false },
      },
    ],
  },
  /**
   * 权限设置
   */
  {
    path: "/authority",
    name: "authority",
    redirect: "/authority/role-list",
    component: AppLayout,
    meta: {
      title: "权限设置",
      icon: "Lock",
    },
    children: [
      {
        path: "role-list",
        name: "RoleList",
        component: () => import("@/views/Authority/Role/ListView.vue"),
        meta: { title: "角色管理" },
      },
      {
        path: "role-edit",
        name: "RoleEdit",
        component: () => import("@/views/Authority/Role/EditView.vue"),
        meta: { title: "新增/编辑角色信息", hidden: true },
      },
      {
        path: "admin-list",
        name: "AdminList",
        component: () => import("@/views/Authority/Admin/ListView.vue"),
        meta: { title: "管理员管理" },
      },
      {
        path: "admin-edit",
        name: "AdminEdit",
        component: () => import("@/views/Authority/Admin/EditView.vue"),
        meta: { title: "新增/编辑管理员信息", hidden: true },
      },
    ],
  },
  /**
   * 系统设置
   */
  {
    path: "/system",
    name: "system",
    redirect: "/system/operation-log",
    component: AppLayout,
    meta: {
      title: "系统设置",
      icon: "Setting",
    },
    children: [
      {
        path: "my-info",
        name: "MyInfo",
        component: () => import("@/views/System/UpdMyInfoView.vue"),
        meta: { title: "个人信息", hidden: false },
      },
      {
        path: "shop-info-set",
        name: "ShopInfoSet",
        component: () => import("@/views/System/ShopInfoSetView.vue"),
        meta: { title: "企业设置", hidden: false },
      },
      {
        path: "son-list",
        name: "SonList",
        component: () => import("@/views/System/SonSystem/SonListView.vue"),
        meta: { title: "子系统管理", hidden: false },
      },
      {
        path: "operation-log",
        name: "OperationLog",
        component: () => import("@/views/System/OperationLogView.vue"),
        meta: { title: "操作日志", hidden: false },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
