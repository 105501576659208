import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_footer = _resolveComponent("el-footer")!

  return (_openBlock(), _createBlock(_component_el_footer, null, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, "VueAdmin @" + _toDisplayString(_ctx.getNowYear()) + " 法默西 版权所有", 1)
    ]),
    _: 1
  }))
}