<template>
  <div class="app-container">
    <el-container class="st-overflow-x st-overflow-y" style="padding: 0">
      <!-- 头部布局 -->
      <AppHeader
        :isCollapse="isCollapse"
        @collapseMenuBtnClick="collapseMenuBtnClick"
      />

      <el-container id="sidebar" class="st-overflow-x st-overflow-y">
        <!-- 侧边栏布局 -->
        <AppSidebar
          :isCollapse="isCollapse"
          @collapseMenuBtnClick="collapseMenuBtnClick"
        />

        <el-container id="main" class="st-overflow-x st-overflow-y">
          <!-- 内容区域 -->
          <AppMain
            :isCollapse="isCollapse"
            @collapseMenuBtnClick="collapseMenuBtnClick"
          />

          <!-- 页面底部 -->
          <AppFooter />
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppFooter, AppMain, AppHeader, AppSidebar } from "./components";

export default defineComponent({
  name: "AppLayout",
  components: {
    AppFooter,
    AppMain,
    AppSidebar,
    AppHeader,
  },
  data() {
    return {
      isCollapse: false, // 菜单默认不折叠
    };
  },
  methods: {
    /**
     * 点击收缩按钮触发 collapse为false时展开，为true时折叠。
     */
    collapseMenuBtnClick() {
      this.isCollapse = !this.isCollapse;
    },
  },
});
</script>

<style lang="scss">
.app-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .el-container:first-child {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    #sidebar {
      overflow-y: auto;
      height: calc(100% - var(--top-tool-height));
    }
    #main {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: calc(100% - var(--el-main-height));
    }
  }
}
</style>
