import { isNotEmpty } from "@/utils/helper";
import { getAgentConfig } from "@/api/home";

export function agentConfig(configData: any) {
  let wx = (window as any).wx ? (window as any).wx : (window as any).jWeixin;

  if (!configData) {
    return false;
  }

  /**
   * 配置信息
   */
  wx.agentConfig({
    // beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    corpid: configData.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
    agentid: configData.agentid, // 必填，企业微信的应用id （e.g. 1000247）
    timestamp: configData.timestamp, // 必填，生成签名的时间戳
    nonceStr: configData.noncestr, // 必填，生成签名的随机串
    signature: configData.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
    jsApiList: configData.jsApiList, // 必填，传入需要使用的接口名称
    success: function (res: any) {
      // 回调
      console.log("agentconfig回调：success");
      console.log(res);

      console.log(
        "window.(window as any).WWOpenData",
        (window as any).WWOpenData
      );
      /**
       * 检测登录状态
       */
      if ((window as any).WWOpenData.checkSession) {
        (window as any).WWOpenData.checkSession({
          success() {
            console.log("checkSession open-data 登录态校验成功");
            (window as any).WWOpenData.bindAll(
              document.querySelector("ww-open-data")
            );
          },
          fail() {
            console.log("checkSession open-data 登录态过期");
          },
        });
      }

      /**
       * ww-open-data 获取数据失败时触发
       */
      if ((window as any).WWOpenData.log) {
        (window as any).WWOpenData.log({
          success(event: any) {
            console.log("log获取数据失败", event);
          },
          fail: function (event: any) {
            console.log("log获取数据失败", event);
          },
        });
      }
    },
    fail: function (res: any) {
      console.log("agentconfig回调：fail", res);

      if (res.errMsg.indexOf("function not exist") > -1) {
        alert("版本过低请升级");
      }
    },
  });
}
export function config(configData: any, agentConfigData: any) {
  /*
   *android ios 端设置企业微信配置
   */
  let wx = (window as any).wx ? (window as any).wx : (window as any).jWeixin;

  // 初始化内容
  wx.config({
    beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: configData.corpid, // 必填，企业微信的corpID
    timestamp: configData.timestamp, // 必填，生成签名的时间戳
    nonceStr: configData.noncestr, // 必填，生成签名的随机串
    signature: configData.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
    jsApiList: configData.jsApiList, // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
  });

  wx.ready(function (res: any) {
    console.log("wx.ready", res);
    wx.hideAllNonBaseMenuItem();

    agentConfig(agentConfigData);
  });

  wx.checkJsApi({
    jsApiList: configData.jsApiList,
    success: function (res: any) {
      console.log("checkJsApi success", res);
    },
  });

  wx.error(function (res: any) {
    console.log("wx.error", res);
  });
}

export function initConfig(configData: any, agentConfigData: any) {
  let isComWx = /wxwork/i.test(navigator.userAgent);

  (async () => {
    /**
     * 企业微信
     */
    if (isComWx) {
      config(configData, agentConfigData);
    } else {
      agentConfig(agentConfigData);
    }
  })();
}

export function initGetAgentConfig(parameter: any) {
  getAgentConfig(parameter).then((res: any) => {
    console.log("getAgerentConfig", res);
    if (isNotEmpty(res)) {
      initConfig(res.data.config, res.data.agentConfig);
    }
  });
}
