<template>
  <el-footer>
    <p style="margin: 0">VueAdmin @{{ getNowYear() }} 法默西 版权所有</p>
  </el-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  methods: {
    getNowYear() {
      if (new Date().getFullYear() == 2022) {
        return new Date().getFullYear();
      } else {
        return "2022-" + new Date().getFullYear();
      }
    },
  },
});
</script>

<style lang="less" scoped>
.el-footer {
  height: calc(var(--app-footer-height) - 10px);
  line-height: calc(var(--app-footer-height) - 10px);
  color: var(--app-footer-color);
}
</style>
