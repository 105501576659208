<template>
  <el-main class="st-overflow-x st-overflow-y">
    <div style="display: flex; align-items: center; position: relative">
      <el-icon
        class="el-input__icon"
        :size="30"
        @click="collapseClicks"
        :title="isCollapse ? `打开菜单` : `关闭菜单`"
      >
        <Expand v-if="isCollapse" />
        <Fold v-else />
      </el-icon>
      &nbsp;&nbsp;&nbsp;
      <el-breadcrumb class="bread" separator="/" style="min-width: 300px">
        <el-breadcrumb-item
          :to="{ path: item.path }"
          v-for="(item, index) in $route.matched"
          :key="index"
        >
          {{ item.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <el-icon
        class="el-input__icon"
        :size="30"
        @click="handleRefresh"
        title="刷新页面"
        style="position: absolute; right: 30px"
      >
        <Refresh />
      </el-icon>
    </div>

    <el-divider style="margin: 10px 0" />
    <router-view />
  </el-main>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppMain",
  emits: ["collapseMenuBtnClick"],
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleRefresh() {
      this.$router.go(0);
    },
    collapseClicks() {
      this.$emit("collapseMenuBtnClick");
    },
  },
});
</script>

<style lang="less" scoped>
.el-main {
  min-height: var(--app-content-height);
  // padding: var(--el-main-padding);
  padding: 20px 10px 5px !important;
  background-color: var(--app-contnet-bg-color);
  text-align: left;
}

// 饿了么
:deep(.avatar-uploader) {
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }

  .el-upload:hover {
    border-color: var(--el-color-primary);
  }
}

:deep(.el-icon.avatar-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
