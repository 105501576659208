import { useAxios } from "@/utils/useAxios";

const request = useAxios();

/**
 * 星级考试开启设置信息
 * @param params
 * @returns
 */
export const getExamStar = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/exam-star/star", params });

  return res && res.data;
};

/**
 * 星级考试开启设置保存
 * @param data
 * @returns
 */
export const postExamStarSave = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam-star/star-save", data });

  return res && res.data;
};

/**
 * 星级考试 消息发送
 * @param data
 * @returns
 */
export const postExamStarMessage = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam-star/star-message", data });

  return res && res.data;
};

/**
 * 星级考试 消息发送保存
 * @param data
 * @returns
 */
export const postExamStarMessageSave = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam-star/star-message-save", data });

  return res && res.data;
};

/**
 * 星级考试 消息列表
 * @param data
 * @returns
 */
export const getExamStarMessageList = async (
  params: any
): Promise<Response> => {
  const res = await request.post({
    url: "/exam-star/star-message-list",
    params,
  });

  return res && res.data;
};

/**
 * 考试列表
 * @param params
 * @returns
 */
export const getExamList = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/exam/list", params });

  return res && res.data;
};

/**
 * 考试信息 第一步
 * @param params
 * @returns
 */
export const getStepOne = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/exam/step-one", params });

  return res && res.data;
};

/**
 * 考试信息 第一步 保存
 * @param data
 * @returns
 */
export const postStepOneSave = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam/step-one-save", data });

  return res && res.data;
};

/**
 * 考试信息 第二步
 * @param params
 * @returns
 */
export const getStepTwo = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/exam/step-two", params });

  return res && res.data;
};

/**
 * 考试信息 第二步 保存
 * @param data
 * @returns
 */
export const postStepTwoSave = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam/step-two-save", data });

  return res && res.data;
};

/**
 * 考试信息 第三步
 * @param params
 * @returns
 */
export const getStepThree = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/exam/step-three", params });

  return res && res.data;
};

/**
 * 考试信息 第三步 保存
 * @param data
 * @returns
 */
export const postStepThreeSave = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam/step-three-save", data });

  return res && res.data;
};

/**
 * 回收考试-修改状态，删除考试，假删除
 * @param data
 * @returns
 */
export const postExamPublish = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam/publish", data });

  return res && res.data;
};

/**
 * 回收考试-修改状态，删除考试，假删除
 * @param data
 * @returns
 */
export const postExamRemove = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam/remove", data });

  return res && res.data;
};

/**
 * 结束考试
 * @param params
 * @returns
 */
export const postEndExamStatus = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/exam/end-exam-status",
    data,
  });

  return res && res.data;
};
/**
 * 删除考试
 * @param data
 * @returns
 */
export const postExamDelete = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/exam/delete", data });

  return res && res.data;
};

/**
 * 查看/打印试卷
 * @param params
 * @returns
 */
export const getShowPrintExamPaper = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/exam/show-print-exam-paper",
    params,
  });

  return res && res.data;
};

/**
 * 新建推送
 * @param params
 * @returns
 */
export const postNewPushExam = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/exam/new-push-exam",
    data,
  });

  return res && res.data;
};

/**
 * 发送催办消息
 * @param params
 * @returns
 */
export const postUrgingExam = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/exam/urging-exam",
    data,
  });

  return res && res.data;
};

/**
 * 考试分类列表
 * @param params
 * @returns
 */
export const getTypeList = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/exam/type-list",
    params,
  });

  return res && res.data;
};

/**
 * 考试分类详情
 * @param params
 * @returns
 */
export const getTypeInfo = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/exam/type-info",
    params,
  });

  return res && res.data;
};

/**
 * 考试分类编辑
 * @param params
 * @returns
 */
export const postTypeEdit = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/exam/type-edit",
    data,
  });

  return res && res.data;
};

/**
 * 考试分类删除
 * @param params
 * @returns
 */
export const postTypeDelete = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/exam/type-delete",
    data,
  });

  return res && res.data;
};
