import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    class: "flow-select",
    modelValue: _ctx.filters.filTitle,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.filTitle) = $event)),
    placeholder: "请选择分类",
    style: _normalizeStyle(_ctx.style)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        placeholder: "输入关键字进行过滤",
        modelValue: _ctx.filterText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_option, {
        hidden: "",
        label: _ctx.filters.filTitle,
        value: _ctx.filters.filId
      }, null, 8, ["label", "value"]),
      _createVNode(_component_el_tree, {
        ref: "selectTree",
        class: "flow-tree st-overflow-x st-overflow-y",
        props: _ctx.propsRule,
        data: _ctx.listData,
        "node-key": "id",
        filterable: _ctx.filterable,
        disabled: _ctx.disabled,
        "auto-expand-parent": true,
        "highlight-current": true,
        "default-expanded-keys": _ctx.defaultExpandedKeys,
        "filter-node-method": _ctx.filterNode,
        onNodeClick: _ctx.handleNodeClick
      }, null, 8, ["props", "data", "filterable", "disabled", "default-expanded-keys", "filter-node-method", "onNodeClick"])
    ]),
    _: 1
  }, 8, ["modelValue", "style"]))
}