<template>
  <el-popover placement="top-start" :width="width" trigger="hover">
    <template #reference>
      <component :is="icon" :style="iconStyle" />
    </template>
    <template #default>
      <el-alert
        :show-icon="alertShowIcon"
        :type="alertType"
        :closable="alertClosable"
        :effect="alertEffect"
      >
        <template #title>
          <span v-html="content"></span>
        </template>
      </el-alert>
    </template>
  </el-popover>
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "PopoverAlert",

  /**
   * 接收父页面传递的值
   */
  props: {
    width: {
      type: Number,
      default: () => {
        return 300;
      },
    },
    icon: {
      type: String,
      default: () => {
        return "QuestionFilled";
      },
    },
    iconStyle: {
      type: Object,
      default: () => {
        return { width: "20px", marginLeft: "10px" };
      },
    },
    alertShowIcon: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    alertType: {
      type: String,
      default: () => {
        return "info";
      },
    },
    alertEffect: {
      type: String,
      default: () => {
        return "light";
      },
    },
    alertClosable: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    content: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
});
</script>

<style lang="less" scoped></style>
