  // export const TINY_PLUGINS =
//   "advlist anchor autolink " +
//   "charmap code codesample directionality emoticons " +
//   "fullscreen image importcss indent2em insertdatetime " +
//   "link lists media nonbreaking pagebreak preview " +
//   "quickbars save searchreplace table template " +
//   "visualblocks visualchars wordcount";

// export const TINY_TOOLBAR =
//   "code | undo redo | removeformat searchreplace | " +
//   "alignleft aligncenter alignright alignjustify lineheight indent2em | " +
//   "numlist bullist table | link unlink | " +
//   "fontfamily fontsize | forecolor backcolor | " +
//   "bold italic underline strikethrough | " +
//   "superscript subscript | hr charmap | ";

export const TINY_PLUGINS =
"advlist anchor autolink " +
"charmap code codesample directionality emoticons " +
"fullscreen importcss insertdatetime " +
"link lists media nonbreaking pagebreak preview " +
"quickbars save searchreplace table template " +
"visualblocks visualchars wordcount";

export const TINY_TOOLBAR =
"undo redo | fontfamily fontsize | removeformat searchreplace | " +
"alignleft aligncenter alignright alignjustify lineheight | " +
"numlist bullist table | link unlink | " +
"forecolor backcolor | " +
"bold italic underline strikethrough | " +
"superscript subscript | hr charmap | image uploadimagemore video";

export const TINY_FONT_FAMILY_FORMATS =
"微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';" +
"Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;" +
"Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Impact=impact,chicago;" +
"Terminal=terminal,monaco;Times New Roman=times new roman,times;";

export const TINY_FONT_SIZE_FORMATS =
"12px 13px 14px 15px 16px 17px 18px 19px 20px 22px 24px 36px";

