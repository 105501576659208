import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";

import { ElMessage } from "element-plus";
import CryptoJS from "crypto-js";

import qs from "qs";
import sha1 from "js-sha1";
// import md5 from "js-md5";
import router from "@/router";

declare type AxiosHeaders =
  | "application/json"
  | "application/x-www-form-urlencoded"
  | "multipart/form-data";

const config: {
  baseUrl: {
    dev: string;
    pro: string;
  };
  resultCode: number | string;
  defaultHeaders: AxiosHeaders;
  requestTimeout: number;
} = {
  /**
   * api请求基础路径
   */
  baseUrl: {
    // 开发环境接口前缀
    // dev: "http://www.famoxi.net/v1",
    // 打包生产环境接口前缀
    // pro: "http://www.famoxi.net/v1",

    // 开发环境接口前缀
    pro: "https://" + location.hostname + "/v1",
    // 打包生产环境接口前缀
    dev: "https://" + location.hostname + "/v1",
  },

  /**
   * 接口成功返回状态码
   */
  resultCode: 100,

  /**
   * 接口请求超时时间
   */
  requestTimeout: 60000,

  /**
   * 默认接口请求类型
   * 可选值：application/x-www-form-urlencoded multipart/form-data
   */
  defaultHeaders: "application/json",
};

const { resultCode, baseUrl } = config;

const whiteList = ["/login/index"];

let baseURL = baseUrl.pro;

if (process.env.NODE_ENV == "development") {
  baseURL = baseUrl.dev;
}

// 创建axios实例
const service: AxiosInstance = axios.create({
  baseURL: baseURL, // api 的 baseUrl
  timeout: config.requestTimeout, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (requestConfig: AxiosRequestConfig) => {
    if (requestConfig.method === "OPTIONS") {
      return requestConfig;
    }

    /**
     * 请求白名单
     */
    if (whiteList.includes(requestConfig.url + "")) {
      requestConfig.baseURL = window.origin;
    }

    /**
     * 设置post请求带的参数
     */
    if (
      requestConfig.method === "post" &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestConfig.headers["Content-Type"] ===
        "application/x-www-form-urlencoded"
    ) {
      // if (requestConfig.method === "post" && requestConfig.data) {
      requestConfig.data = qs.stringify(requestConfig.data);
    }

    /**
     * 获取 Token
     */
    let loginToken = localStorage.getItem("token");

    /**
     * 添加token，可根据实际业务修改
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requestConfig.headers["Authorization"] = loginToken;

    // eslint-disable-next-line no-use-before-define
    let url = getPayLoad(loginToken);

    url = requestConfig.url + url;
    requestConfig.url = requestConfig.baseURL + url;
    console.log("requestConfig", requestConfig); // for debug

    return requestConfig;
  },
  (error: AxiosError) => {
    // Do something with request error
    // console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    if (
      response.data.code === "0000" ||
      response.data.code === 100 ||
      response.data.errcode == resultCode ||
      response.data.errcode == 200
    ) {
      return response;
    } else if (response.data.code === 40003) {
      // console.log("response.data", response.data);
      /**
       * 删除 Token
       */
      localStorage.removeItem("token");
      localStorage.removeItem("adminInfo");
      ElMessage.error(response.data.msg);
      router.push({
        path: "/login/login",
      });
      return response;
    } else {
      // console.log("response.data", response.data);
      ElMessage.error(response.data.msg);
      return response;
    }
  },
  (error: AxiosError) => {
    // console.log("err", error); // for debug
    ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

/**
 * 通过token生成签名用到的签名连接
 */
function getPayLoad(loginToken: any) {
  /**
   * 设置token解码后的参数
   */
  let decodeData = { secret: "", info: {} };

  /**
   * 存在token 进行解码
   */
  if (loginToken) {
    let tokenArr = loginToken.split(".", 3);

    decodeData = JSON.parse(
      CryptoJS.enc.Base64.parse(tokenArr[1]).toString(CryptoJS.enc.Utf8)
    );
  }

  /**
   * 拼接要用的secret参数
   */
  let secret = decodeData ? (decodeData.secret ? decodeData.secret : "") : "";

  /**
   * 获取10位时间戳
   */
  let timestamp = parseInt((new Date().getTime() / 1000).toString());
  let nonce = "fmxJwtServer";

  /**
   * 生成请求签名
   */
  let signString = timestamp + nonce + secret;
  let sign = sha1(signString).toString();
  let url = "?timestamp=" + timestamp + "&nonce=" + nonce + "&sign=" + sign;

  return url;
}

export { service };
