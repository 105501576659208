import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center","position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Expand = _resolveComponent("Expand")!
  const _component_Fold = _resolveComponent("Fold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, { class: "st-overflow-x st-overflow-y" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_icon, {
          class: "el-input__icon",
          size: 30,
          onClick: _ctx.collapseClicks,
          title: _ctx.isCollapse ? `打开菜单` : `关闭菜单`
        }, {
          default: _withCtx(() => [
            (_ctx.isCollapse)
              ? (_openBlock(), _createBlock(_component_Expand, { key: 0 }))
              : (_openBlock(), _createBlock(_component_Fold, { key: 1 }))
          ]),
          _: 1
        }, 8, ["onClick", "title"]),
        _cache[0] || (_cache[0] = _createTextVNode("     ")),
        _createVNode(_component_el_breadcrumb, {
          class: "bread",
          separator: "/",
          style: {"min-width":"300px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$route.matched, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                to: { path: item.path },
                key: index
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.meta.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_el_icon, {
          class: "el-input__icon",
          size: 30,
          onClick: _ctx.handleRefresh,
          title: "刷新页面",
          style: {"position":"absolute","right":"30px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Refresh)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createVNode(_component_el_divider, { style: {"margin":"10px 0"} }),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}