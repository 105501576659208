import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    ref: "menu",
    class: "sidebar-el-menu el-aside st-overflow-x st-overflow-y",
    "default-active": _ctx.$route.path,
    router: _ctx.router,
    "background-color": "#273043",
    "text-color": "#ffffff",
    "active-text-color": "#2d8cf0",
    "default-openeds": _ctx.defaultOpeneds,
    "show-timeout": 0,
    "hide-timeout": 0,
    collapse: _ctx.isCollapse,
    onOpen: _ctx.handleOpen,
    onClose: _ctx.handleClose,
    "close-on-click-outside": false,
    "collapse-transition": false,
    style: _normalizeStyle(!_ctx.isCollapse ? `width: var(--left-menu-max-width)` : `width: 50px`)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MenuItem, {
        routes: _ctx.routes,
        isCollapse: _ctx.isCollapse
      }, null, 8, ["routes", "isCollapse"])
    ]),
    _: 1
  }, 8, ["default-active", "router", "default-openeds", "collapse", "onOpen", "onClose", "style"]))
}