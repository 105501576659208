import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppSidebar = _resolveComponent("AppSidebar")!
  const _component_AppMain = _resolveComponent("AppMain")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, {
      class: "st-overflow-x st-overflow-y",
      style: {"padding":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AppHeader, {
          isCollapse: _ctx.isCollapse,
          onCollapseMenuBtnClick: _ctx.collapseMenuBtnClick
        }, null, 8, ["isCollapse", "onCollapseMenuBtnClick"]),
        _createVNode(_component_el_container, {
          id: "sidebar",
          class: "st-overflow-x st-overflow-y"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppSidebar, {
              isCollapse: _ctx.isCollapse,
              onCollapseMenuBtnClick: _ctx.collapseMenuBtnClick
            }, null, 8, ["isCollapse", "onCollapseMenuBtnClick"]),
            _createVNode(_component_el_container, {
              id: "main",
              class: "st-overflow-x st-overflow-y"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AppMain, {
                  isCollapse: _ctx.isCollapse,
                  onCollapseMenuBtnClick: _ctx.collapseMenuBtnClick
                }, null, 8, ["isCollapse", "onCollapseMenuBtnClick"]),
                _createVNode(_component_AppFooter)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}