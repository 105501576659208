<template>
  <ww-open-data :type="type" :openid="openid" />
</template>

<script>
export default {
  name: "OpenData",
  props: ["type", "openid"],
  mounted() {
    window.WWOpenData.bind(this.$el);
  },
};
</script>
