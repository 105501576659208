/**
 * 控制富文本中图片大小
 */
export function formatRichText(html: string) {
  if (html == "" || html == null || html == undefined) {
    return html;
  }

  console.log("formatRichText", html);

  let newContent = html.replace(/<img[^>]*>/gi, function (match) {
    match = match.replace(/style="[^"]+"/gi, "").replace(/style='[^']+'/gi, "");
    match = match.replace(/width="[^"]+"/gi, "").replace(/width='[^']+'/gi, "");
    match = match
      .replace(/height="[^"]+"/gi, "")
      .replace(/height='[^']+'/gi, "");
    return match;
  });

  newContent = newContent.replace(/style="[^"]+"/gi, function (match) {
    match = match
      .replace(/width:[^;]+;/gi, "max-width:100%;")
      .replace(/width:[^;]+;/gi, "max-width:100%;");
    return match;
  });

  newContent = newContent.replace(/<br[^>]*\/>/gi, "");
  newContent = newContent.replace(
    /\\<img/gi,
    '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"'
  );
  return newContent;
}

interface Response {
  data: string | number | { [key: string]: any } | any[] | null | undefined;
}
export function isNotEmpty(response: Response): boolean {
  if (response.data == null) {
    return false;
  }

  if (typeof response.data === "string" || typeof response.data === "number") {
    return response.data !== "";
  }

  if (Array.isArray(response.data)) {
    return response.data.length > 0;
  }

  if (typeof response.data === "object") {
    return Object.keys(response.data).length > 0;
  }

  return true; // 如果是其他类型，可以根据需要进行判断
}
