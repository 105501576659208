<template>
  <div class="menu-div" v-for="(item, index) in routes" :key="index">
    <!-- 没有子节点，使用 el-menu-item 渲染 -->
    <!-- 顶级菜单存在 首页 -->
    <el-sub-menu
      :index="item.redirect"
      :route="item.redirect"
      v-if="
        item.meta &&
        !item.meta.hidden &&
        item.children &&
        item.redirect == '/home/index'
      "
      :item="item.meta && item.meta.title"
      tabindex="menu-746893"
    >
      <template #title>
        <component
          v-if="isCollapse"
          class="icons"
          :is="item.meta && item.meta.icon"
          style="margin-left: 10px"
        ></component>

        <span class="el-dropdown-span" v-if="!isCollapse">
          <el-menu-item :index="item.redirect" :route="item.redirect">
            <component
              class="icons"
              :is="item.meta && item.meta.icon"
            ></component>
            {{ item.meta && item.meta.title }}
          </el-menu-item>
        </span>
      </template>
      <!-- 循环渲染 -->
      <div class="menu-sub-div" v-if="isCollapse">
        <el-menu-item
          :index="item.redirect"
          :route="item.redirect"
          :item="item.meta && item.meta.title + `menu-sub-div-${index}`"
        >
          {{ item.meta && item.meta.title }}
        </el-menu-item>
      </div>
    </el-sub-menu>

    <!-- 有子节点，使用 el-sub-menu 渲染 -->
    <!-- 顶级菜单存在并且不隐藏存在子菜单 -->
    <el-sub-menu
      :index="item.redirect"
      :route="item.redirect"
      v-else-if="
        item.meta &&
        !item.meta.hidden &&
        item.children &&
        item.redirect != '/home/index'
      "
      :item="item.meta && item.meta.title"
    >
      <template #title>
        <component
          v-if="isCollapse"
          class="icons"
          :is="item.meta && item.meta.icon"
        ></component>
        <!-- 悬浮 -->
        <el-dropdown
          v-if="!isCollapse"
          class="el-dropdown-p"
          :hide-on-click="false"
          placement="right-start"
          tabindex="menu-224326"
        >
          <span class="el-dropdown-span">
            <component
              class="icons"
              :is="item.meta && item.meta.icon"
            ></component>
            {{ item.meta && item.meta.title }}
          </span>
          <template #dropdown>
            <!-- 悬浮子集 -->
            <div v-for="(sub, subIndex) in item.children" :key="subIndex">
              <el-dropdown-item
                class="el-dropdown-item-p"
                :item="
                  sub.meta && sub.meta.title + `menu-dropdown-sub-${subIndex}`
                "
                v-if="sub.meta && !sub.meta.hidden && !sub.children"
              >
                <el-menu-item
                  :route="item.path + '/' + sub.path"
                  :index="item.path + '/' + sub.path"
                >
                  {{ sub.meta && sub.meta.title }}
                </el-menu-item>
              </el-dropdown-item>
            </div>
          </template>
        </el-dropdown>
      </template>

      <!-- 循环渲染 -->
      <div
        class="menu-sub-div"
        v-for="(sub, subIndex) in item.children"
        :key="subIndex"
      >
        <!-- 没有子节点，使用 el-menu-item 渲染 -->
        <el-menu-item
          :route="item.path + '/' + sub.path"
          :index="item.path + '/' + sub.path"
          v-if="sub.meta && !sub.meta.hidden && !sub.children"
          :item="sub.meta && sub.meta.title + `menu-sub-div-${subIndex}`"
        >
          {{ sub.meta && sub.meta.title }}
        </el-menu-item>
        <!-- <el-menu-item
          :route="item.path + '/' + sub.path"
          :index="item.path + '/' + sub.path"
          v-else-if="sub.meta && !sub.meta.hidden && sub.children"
        >
          <MenuItem :routes="sub" />
        </el-menu-item> -->
      </div>
    </el-sub-menu>
  </div>
</template>

<script lang="ts" scoped>
export default {
  name: "MenuItem",
  components: {},
  props: {
    routes: {
      type: Object as () => any,
      default() {
        return {};
      },
    },
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="less">
/** 图标 */
.icons {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.el-dropdown-span {
  display: flex;
  align-items: center;
  height: 50px !important;
}

/** 修改下拉悬浮的样式 */
.el-dropdown-p {
  display: inline-grid !important;
  text-align: left !important;
  color: #ffffff !important;
  line-height: revert !important;
  width: 100% !important;
  max-width: 300px;
  overflow: hidden;

  :hover,
  :focus {
    outline: 0 !important;
  }
}
.el-dropdown-menu {
  padding: 0px !important;
}
/** 修改下拉悬浮的箭头样式 */
.el-dropdown__popper.el-popper[data-popper-placement^="right"]
  .el-popper__arrow::before {
  background-color: #ffffff !important;
  border: 0 solid #ffffff;
}

/** 修改下拉悬浮的边框样式 */
.el-dropdown__popper.el-popper[data-popper-placement^="right"] {
  border: 0 solid #ffffff;
}

/** 修改下拉悬浮的样式 */
.el-dropdown-item-p {
  background-color: #475677 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  padding: 0 !important;
  display: block !important;
  height: 50px !important;

  .el-menu-item:first-child {
    font-size: 12px !important;
    padding: 10px 20px !important;
    height: 50px !important;
    width: 100% !important;
  }
}

.el-menu--popup {
  min-width: auto !important;

  .menu-sub-div {
    .el-menu-item {
      width: 100% !important;
      padding: 10px 20px !important;
    }
  }
}

/** 菜单 文字 首页 字体 */
.el-sub-menu[tabindex^="menu-746893"][item^="首页概况"] {
  display: inline-grid !important;
  font-size: 14px !important;
  height: 50px !important;
  width: 100% !important;
}
.el-sub-menu[tabindex^="menu-746893"][item^="首页概况"] .el-sub-menu__title {
  display: flex !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
  align-content: center;
  height: 50px !important;
  padding: 0 !important;
}
.el-sub-menu[tabindex^="menu-746893"][item^="首页概况"] .el-dropdown-span {
  width: 100% !important;
}
.el-sub-menu[tabindex^="menu-746893"][item^="首页概况"]
  .el-dropdown-span:hover {
  background-color: transparent !important;
  display: flex !important;
}

.el-sub-menu[tabindex^="menu-746893"][item^="首页概况"]
  .el-sub-menu__icon-arrow {
  display: none;
}

.el-sub-menu[tabindex^="menu-746893"][item^="首页概况"] .el-menu-item {
  width: 100% !important;
  font-size: 14px !important;
  font-weight: 700;
}

.el-menu-item,
.el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
  width: auto !important;
}
/* 顶级菜单项的样式 */
.el-sub-menu__title {
  display: flex !important;
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 700;
  padding-right: 0 !important;
  align-content: center;
  height: 50px !important;
}
.el-sub-menu__title:hover {
  background-color: #133f6d !important;
}
.el-menu--inline {
  text-align: left;
  padding-left: 15px !important;
}

/** 二级菜单的样式 */
.el-menu--inline div {
  display: inline-block;
}
.el-menu-item {
  --el-menu-text-color: #ffffff !important;
  --el-menu-bg-color: #323e56 !important;
  --el-menu-hover-text-color: #ffffff !important;
  --el-menu-hover-bg-color: #2d8cf0 !important;
  --el-menu-active-color: #2d8cf0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  display: inline-flex !important;
  text-align: center !important;
  font-size: 12px !important;
  text-align: center;
}
.el-menu-item :hover {
  background-color: #2d8cf0 !important;
}
.el-menu-item.is-active {
  color: white !important;
  background: #3989fa !important;
}
</style>
