import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { RouterView } from "vue-router";
import zhCn from "element-plus/es/locale/lang/zh-cn";

/**
 * 分页 自定义文字（使用默认文字不用设置）
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

zhCn.el.pagination = {
  goto: "跳转",
  pagesize: "条/页",
  total: "共{total}条",
  pageClassifier: "",
  page: "",
  prev: "",
  next: "",
  currentPage: "",
  prevPages: "",
  nextPages: "",
  deprecationWarning: "",
};

/**
 * 处理el-table的ResizeObserver loop limit exceeded问题
 */
const debounce = (fn: any, delay: any) => {
  let timer: number | undefined;
  // eslint-disable-next-line no-underscore-dangle, no-invalid-this
  let $this = this as any;

  return function () {
    let context: any = $this;
    let args = arguments;

    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
// eslint-disable-next-line no-underscore-dangle
const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  // eslint-disable-next-line no-undef
  constructor(callback: ResizeObserverCallback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createBlock(_component_el_config_provider, { locale: _unref(zhCn) }, {
    default: _withCtx(() => [
      _createVNode(_unref(RouterView))
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})