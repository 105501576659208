<template>
  <el-upload
    class="upload-image-uploader"
    :show-file-list="false"
    :on-success="handleUploadImageSuccess"
    :before-upload="beforeUploadImageUpload"
    :http-request="onSubmit"
    :disabled="imgDisable"
  >
    <img
      v-if="imageUrl != '' || selectImage != ''"
      :src="`https:` + (imageUrl != '' ? imageUrl : selectImage)"
      class="upload-image"
      :style="style"
    />
    <el-icon v-else class="upload-image-uploader-icon"><Plus /></el-icon>
  </el-upload>
  &nbsp;
  <el-button @click="handlePictureCardPreview"> 图片预览 </el-button>
  <el-image-viewer
    v-if="dialogVisible"
    :url-list="[`https:` + dialogImageUrl]"
    preview-teleported
    @close="dialogVisible = false"
    z-index="999999"
  />
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import COS from "cos-js-sdk-v5";

import { isNotEmpty } from "@/utils/helper";

import { tencentCloudCosConfig } from "@/api/uploads";

export default defineComponent({
  components: {},
  name: "UploadImg",

  /**
   * 接收父页面传递的值
   * onSaveImage 保存图片
   * selectImage 选择图片
   * style 样式
   * isFileType 图片格式
   * fileSize 图片大小
   */
  emits: [
    "onSaveImage",
    "selectImage",
    "style",
    "isFileType",
    "fileSize",
    "imgDisable",
  ],

  props: {
    selectImage: {
      type: String,
      default: () => {
        return "";
      },
    },
    style: {
      type: String,
      default: () => {
        return "";
      },
    },
    isFileType: {
      type: Array,
      default: () => {
        let data = ["isJPG", "isPNG", "isGIF"];

        return data;
      },
    },
    fileSize: {
      type: Array,
      default: () => {
        let data = [200, 1024, 1];

        return data;
      },
    },
    imgDisable: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      imageUrl: "",
    };
  },
  created() {
    console.log(
      "created",
      "imageUrl",
      this.imageUrl,
      "selectImage",
      this.selectImage
    );
  },
  methods: {
    /**
     * 上传前的校验
     * @param file 上传的文件
     */
    beforeUploadImageUpload(file: any) {
      console.log("beforeUploadImageUpload", "file", file);

      if (!this.isFileType) {
        ElMessage.error("请设置可以上传的图片格式!");
        return false;
      }

      if (!this.fileSize || this.fileSize.length < 3) {
        ElMessage.error("请设置可以上传的图片大小!");
        return false;
      }

      /**
       * 上传的图片格式
       */
      const fileType = {
        "image/jpeg": "isJPG",
        "image/png": "isPNG",
        "image/gif": "isGIF",
      } as any;

      let isType = false as any;

      isType = fileType[file.type] ?? false;

      if (!isType) {
        ElMessage.error("上传图片的格式不在可上传范围内！");
        return false;
      }

      if (!this.isFileType.includes(isType)) {
        ElMessage.error(
          "上传的图片格式不正确，上传图片只能是" +
            this.isFileType.join("、") +
            "格式！"
        );
        return false;
      }

      let isSize =
        parseFloat(this.fileSize[0] as any) *
        (parseFloat(this.fileSize[1] as any) *
          parseFloat(this.fileSize[2] as any));

      const isFileSize = file.size - isSize;

      if (isFileSize > 0) {
        ElMessage.error("上传图片大小不能超过" + this.fileSize[0] + "KB！");
        return false;
      }

      return this.isFileType.includes(isType) && isType && isFileSize;
    },

    /**
     * 点击保存
     */
    onSubmit(params: any) {
      console.log("onSubmit");
      const file = params.file;

      this.tencentCloudCosConfig(file);
    },

    /**
     * 获取腾讯云存储桶的配置信息
     */
    async tencentCloudCosConfig(file: any) {
      tencentCloudCosConfig({}).then((res: any) => {
        if (isNotEmpty(res)) {
          this.uploadToCOS(res.data, file);
        } else {
          ElMessage.error(res.msg);
        }
      });
    },

    /**
     * 腾讯云上传到腾讯云
     * @param config
     * @param file
     */
    async uploadToCOS(config: any, file: any) {
      const cos = new COS({
        SecretId: config.secretId,
        SecretKey: config.secretKey,
      });

      // AKIDAAuQYQ2NTJvGMBj26vUnpc8RUJtEJ8h0
      console.log("config:", config);
      console.log("file:", file);
      // config.filePath += `${new Date().getTime()}-${file.name}`;

      config.filePath += file.name;

      cos.putObject(
        {
          Bucket: config.bucket,
          Region: config.region,
          Key: config.filePath,
          Body: file,
          onProgress: (progressData) => {
            console.log("progressData", JSON.stringify(progressData));
          },
        },
        (err, data) => {
          console.log(err || data);
          if (err) {
            ElMessage.error("上传失败！" + err);
            this.$emit("onSaveImage", this.selectImage);
            return false;
          }

          this.imageUrl = "//" + data.Location;
          ElMessage.success("上传成功！");
          this.$emit("onSaveImage", this.imageUrl);
          return true;
        }
      );
    },

    /**
     * 上传成功后
     */
    handleUploadImageSuccess(res: any, file: any) {
      console.log("handleUploadImageSuccess", "res", res, "file", file);
    },

    /**
     * 预览图片
     */
    handlePictureCardPreview() {
      this.dialogImageUrl =
        this.imageUrl != "" ? this.imageUrl : this.selectImage;

      if (this.dialogImageUrl == "") {
        ElMessage.error("请先上传图片！在进行预览");
        return false;
      }

      this.dialogVisible = true;
    },
  },
});
</script>

<style lang="less" scoped>
:deep(.image_dialog) {
  width: 80%;
  max-width: 900px;
  min-width: 900px;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: Center; */
  overflow: hidden;
  .el-dialog {
    margin: 0 auto !important;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      padding: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

:deep(.upload-image-uploader .el-upload) {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

:deep(.upload-image-uploader .el-upload:hover) {
  border-color: var(--el-color-primary);
}

:deep(.el-icon.upload-image-uploader-icon) {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  text-align: center;
}
:deep(.upload-image) {
  width: 50px;
  height: 50px;
  display: block;
}
</style>
