<template>
  <div>
    <el-tour v-model="open" :show-close="false" @change="handleChange">
      <el-tour-step
        v-for="(item, index) in data"
        :key="index"
        :target="item.target"
        :title="item.title || `第${currentStep + 1}步`"
        :description="item.description"
        :prev-button-text="'上一步'"
        :next-button-text="nextBtnName"
        @prev-click="handlePrevClick"
        @next-click="handleNextClick"
      >
        <template v-slot:header>
          {{ item.title || `第${currentStep + 1}步` }}
        </template>
        <template v-slot:default>
          <div v-html="item.description"></div>
        </template>
      </el-tour-step>
      <template v-slot:indicators>
        <el-button size="small" @click="handleSkip">跳过</el-button>
      </template>
    </el-tour>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuideTour",

  props: {
    data: {
      // {
      //   "target": "# . 元素id或者元素class",
      //   "title": "",
      //   "description": "内容描述"
      // }
      type: Array as any,
      default: () => {
        return [] as any;
      },
    },
  },
  data() {
    return {
      open: true,
      currentStep: 0,
    };
  },
  computed: {
    nextBtnName(): string {
      if (this.currentStep === 0) {
        return "开始";
      } else if (this.currentStep === this.data.length - 1) {
        return "完成";
      } else {
        return `下一步（${this.currentStep + 1} / ${this.data.length}）`;
      }
    },
  },
  methods: {
    handleChange(step: number) {
      this.currentStep = step;
      this.$emit("change", step);
    },
    handleSkip() {
      this.open = false;
    },
    handlePrevClick() {
      this.currentStep--;
      this.$emit("prev", this.currentStep);
    },
    handleNextClick() {
      this.currentStep++;
      this.$emit("next", this.currentStep);
    },
  },
});
</script>

<style scoped>
/* 你的样式 */
.el-tour__content .el-tour-indicators {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}
</style>
