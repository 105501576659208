<template>
  <el-menu
    ref="menu"
    class="sidebar-el-menu el-aside st-overflow-x st-overflow-y"
    :default-active="$route.path"
    :router="router"
    background-color="#273043"
    text-color="#ffffff"
    active-text-color="#2d8cf0"
    :default-openeds="defaultOpeneds"
    :show-timeout="0"
    :hide-timeout="0"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
    :close-on-click-outside="false"
    :collapse-transition="false"
    :style="!isCollapse ? `width: var(--left-menu-max-width)` : `width: 50px`"
  >
    <MenuItem :routes="routes" :isCollapse="isCollapse" />
  </el-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import router from "../../router";
import MenuItem from "./MenuItem.vue";

export default defineComponent({
  components: { MenuItem },
  name: "ApSidebar",
  emits: ["collapseMenuBtnClick"],
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      router: true,
      defaultOpeneds: [
        "/questions/list",
        "/exam/list",
        "/data-center/ranking",
        "/study-plan/index",
        "/wecom/index",
        "/message/index",
        "/download/index",
        "/recycle/index",
        "/authority/role-list",
        "/system/operation-log",
      ] as any,
    };
  },
  computed: {
    routes() {
      return router.options.routes;
    },

    currentName() {
      return this.$route.name;
    },
  },
  watch: {
    isCollapse() {
      for (let i = 0; i < this.defaultOpeneds.length; i++) {
        (this.$refs.menu as any).open(this.defaultOpeneds[i], true);
      }
    },
  },
  methods: {
    handleOpen(key: string, keyPath: string[]) {
      console.log(key, keyPath);
    },
    handleClose(key: string, keyPath: string[]) {
      console.log(key, keyPath);
    },
  },
});
</script>

<style lang="less" scoped>
.sidebar-el-menu {
  min-height: 100%;
}
.el-aside {
  padding: 4px;
  width: var(--left-menu-max-width);
  // background-color: var(--left-menu-bg-color);
  // background-color: #ffffff;
  padding-bottom: 200px;
  background-color: #273043;
  color: #ffffff;
}
</style>
