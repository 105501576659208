<!-- 选择考试分类 -->
<template>
  <div
    v-loading="loading"
    :element-loading-text="loadingText"
    style="height: 100%"
  >
    <el-input
      v-if="showSerach"
      v-model="filterText"
      placeholder="请输入考试分类名称"
      style="padding: 10px"
    >
      <template #suffix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
    </el-input>
    <el-tree
      ref="treeData"
      class="flow-tree st-overflow-x st-overflow-y"
      :disabled="disabled"
      :style="styles"
      :data="listData"
      :props="propsRule"
      node-key="id"
      :highlight-current="highlightCurrent"
      :default-expanded-keys="defaultExpandedKeys"
      :auto-expand-parent="autoExpandParent"
      :show-checkbox="showCheckbox"
      :check-strictly="checkStrictly"
      :default-checked-keys="defaultCheckedKeys"
      :filter-node-method="filterNode"
      :expand-on-click-node="expandNoClickNode"
      :filterable="filterable"
      @node-click="handleNodeClick"
      @node-contextmenu="handleNodeContextmenu"
      @check="handleCheck"
    >
      <template v-slot="{ node, data }">
        <span class="custom-tree-node">
          <span
            class="custom-tree-node-left"
            :title="node.label"
            :style="
              isOptionMenu && data.id > 0
                ? { maxWidth: 'calc(100% - 80px)' }
                : { maxWidth: '100%' }
            "
          >
            <Folder
              style="
                min-width: 20px;
                min-height: 20px;
                max-width: 20px;
                max-height: 20px;
                margin-right: 5px;
              "
            />
            <span class="custom-tree-node-left-text">{{ node.label }}</span>
          </span>

          <span
            class="custom-tree-node-right"
            v-if="isOptionMenu && data.id != 0"
          >
            <el-icon
              style="margin-left: 10px"
              @click="handleOpenEditFun(data, 'add')"
              title="添加"
            >
              <Plus style="color: rgb(39, 99, 220)" />
            </el-icon>
            <el-icon
              v-if="data.id > 0"
              style="margin-left: 10px"
              @click="handleOpenEditFun(data, 'edit')"
              title="修改"
            >
              <Edit style="color: rgb(39, 99, 220)" />
            </el-icon>
            <el-icon
              v-if="data.id > 0"
              style="margin: 0 15px 0 10px"
              @click="handleDelClick(node, data)"
              title="删除"
            >
              <Delete style="color: rgb(245, 108, 108)" />
            </el-icon>
          </span>
        </span>
      </template>
      <template v-slot:empty>
        <el-empty description="没有数据！~"></el-empty>
      </template>
    </el-tree>
  </div>

  <!-- 打开新增/编辑 -->
  <!-- : 用来传递值  @传递父页面的方法 -->
  <ExamTypeEdit
    ref="dialogEditView"
    v-if="openEditPage != ''"
    :typeLists="listData"
    v-model:typeRowId="typeRowId"
    v-model:typeRowPid="typeRowPid"
    v-model:openEditPage="openEditPage"
    @searchWhere="searchTypeWhere"
  />
  <!-- 打开新增/编辑 -->
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";

import { ElMessage, ElMessageBox } from "element-plus";

import { isNotEmpty } from "@/utils/helper";

import { getTypeList, postTypeDelete } from "@/api/exam";

import ExamTypeEdit from "./ExamTypeEdit.vue";

export default defineComponent({
  /**
   * 使用组件
   */
  components: {
    ExamTypeEdit,
  },
  name: "ExamTypeTree",

  /**
   * 接收父页面传递的值
   */
  emits: ["handleTreeChange"],

  props: {
    /**
     * @param Boolean showSerach 是否 显示搜索框 默认true
     * @param Boolean disabled 是否禁用 默认false
     * @param Object styles 样式
     * @param Boolean isChildrenIds 是否 获取子节点id 默认true 获取
     * @param Object parameCriteria 传递 展示列表 的条件
     * @param Boolean defaultPush 是否 显示 追加默认分类列表  true
     * @param Boolean isContextmenu 是否右键菜单 默认false
     * @param Boolean isOptionMenu 是否显示操作菜单 默认false
     */
    showSerach: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isChildrenIds: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    parameCriteria: {
      type: Object,
      default: () => {
        return {};
      },
    },
    defaultPush: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    isContextmenu: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isOptionMenu: {
      type: Boolean,
      default: false,
    },

    /**
     * @param Boolean highlightCurrent 是否高亮 默认true
     * @param Array defaultExpandedKeys 默认展开的节点的 key 的数组
     * @param Boolean autoExpandParent 展开子节点的时候是否自动展开父节点 默认true
     * @param Boolean showCheckbox 是否是 多选 默认false 为单选
     * @param Boolean checkStrictly 在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false
     * @param Array defaultCheckedKeys 默认勾选的节点的 key 的数组
     * @param Boolean expandNoClickNode 是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
     * @param Boolean filterable 过滤所有树节点，过滤后的节点将被隐藏
     */
    highlightCurrent: {
      type: Boolean,
      default: true,
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    autoExpandParent: {
      type: Boolean,
      default: true,
    },
    showCheckbox: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    expandNoClickNode: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      loadingText: "加载中...",

      openEditPage: "",
      typeRowId: 0,
      typeRowPid: 0,

      propsRule: {
        value: "id",
        label: "title",
        children: "children",
      },

      /**
       * 考试分类列表
       */
      idsData: [] as any,
      listData: [] as any,
      defaultType: [
        {
          id: -1,
          title: "所有考试",
          pid: 0,
          children: [],
        },
        {
          id: 0,
          title: "未关联考试",
          pid: 0,
          children: [],
        },
      ],
      filterText: "",
      isExpandAll: false,
      refreshTable: true,
    };
  },
  created() {
    this.initTreeFun();
  },
  watch: {
    filterText(val) {
      if (this.$refs.treeData) {
        (this.$refs.treeData as any).filter(val);
      }
    },
  },
  methods: {
    initTreeFun() {
      this.searchTypeWhere();
    },

    /**
     * 打开添加编译页面
     */
    handleOpenEditFun(row: any, openEditPage: any) {
      /**
       * 有值为修改 没有值为添加
       */
      this.typeRowId = row && row.id > 0 ? row.id : 0;
      this.typeRowPid = row && row.pid > 0 ? row.pid : 0;
      this.openEditPage = openEditPage;

      this.$nextTick(() => {
        (this.$refs["dialogEditView"] as any).initFunEdit();
      });
    },

    /**
     * 删除分类接口
     */
    async postTypeDelete(params: any) {
      postTypeDelete(params).then((res: any) => {
        if (100 == res.errcode) {
          ElMessage.success("删除成功!");
          this.searchTypeWhere();
        }

        this.loading = false;
      });
    },

    /**
     * 删除分类
     */
    handleDelClick(node: any, data: any) {
      if (node.childNodes.length > 0) {
        ElMessage.error("请从最后一级删除");
        return false;
      } else {
        if (!data.id) {
          ElMessage.error("数据不存在!");
          this.searchTypeWhere();
          return false;
        }

        ElMessageBox.confirm("此操作将永久删除该分类, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then((resData: any) => {
            /**
             * 判断操作为提交
             */
            if ("confirm" == resData) {
              this.loading = true;

              let params = { id: data.id };

              this.postTypeDelete(params);
            }
          })
          .catch((error: any) => {
            console.log("cancel", error);
          });
      }
    },

    /**
     * 获取左侧考试分类
     */
    searchTypeWhere() {
      this.listData = [];
      if (this.defaultPush) {
        this.listData = [...this.listData, ...this.defaultType];
      } else {
        this.listData = [];
      }

      this.getTypeList(this.parameCriteria);
    },

    /**
     * 获取考试分类
     */
    async getTypeList(parameter: any) {
      getTypeList(parameter).then((res: any) => {
        if (isNotEmpty(res)) {
          this.listData = [...this.listData, ...res.data];
        }

        this.loading = false;
        this.handelChange(this.defaultCheckedKeys);
      });
    },

    /**
     * 变更选中内容
     */
    handelChange(defaultCheckedKeys: Array<any>) {
      if (defaultCheckedKeys.length <= 0) {
        return false;
      }

      this.$nextTick(() => {
        /**
         * 多选和单选的选中渲染
         */
        if (this.$refs.treeData) {
          if (this.showCheckbox == false) {
            (this.$refs.treeData as any).setCurrentKey(defaultCheckedKeys[0]);
          } else {
            (this.$refs.treeData as any).setCheckedKeys(defaultCheckedKeys);
          }
        }
      });
    },

    /**
     * 右键点击
     */
    handleNodeContextmenu(node: any, data: any) {
      if (this.showCheckbox || !this.isContextmenu) {
        return false;
      }

      this.handelChange([data.id]);
      this.$emit("handleTreeChange", data.id);
    },

    /**
     * 点击节点复选框之后触发
     */
    handleCheck(node: any, checkedData: any) {
      if (!this.showCheckbox) {
        return false;
      }

      let idsData: any = checkedData.checkedKeys;

      idsData.sort(function (a: any, b: any) {
        return a - b;
      });

      let typeIds = idsData.join(",");

      this.$emit("handleTreeChange", typeIds);
    },

    /**
     * 当节点被点击的时候触发
     * @param data 被点击的节点数据
     */
    handleNodeClick(data: any) {
      console.log("handleNodeClick", data);
      if (this.showCheckbox) {
        return false;
      }

      this.idsData = [data.id];

      /**
       * 如果需要包含子节点的 ID，并且当前节点有子节点
       */
      if (
        this.isChildrenIds === true &&
        data.children &&
        data.children.length > 0
      ) {
        // 递归获取子考试分类的 ID
        this.handleChangeChildren(data.children, true);
      }

      let typeIds = this.idsData.join(",");

      this.$emit("handleTreeChange", typeIds);
    },

    /**
     * 点击考试分类获取id，处理多个考试分类及其子分类的选中状态
     * @param data 当前处理的考试分类数据
     * @param isChecked 当前分类是否被选中
     */
    handleChangeChildren(data: any[], isChecked: boolean) {
      if (!data || data.length === 0) {
        return;
      }

      data.forEach((item: any) => {
        const index = this.idsData.indexOf(item.id);

        /**
         * 根据选中状态决定是添加还是删除id
         */
        if (isChecked && index === -1) {
          this.idsData.push(item.id);
        } else if (!isChecked && index !== -1) {
          this.idsData.splice(index, 1);
        }

        /**
         * 如果存在子节点且当前操作导致状态变化（添加或删除），则递归处理子节点
         */
        const shouldRecurse = isChecked !== (index !== -1);

        if (shouldRecurse && item.children && item.children.length > 0) {
          this.handleChangeChildren(item.children, isChecked);
        }
      });
    },

    /**
     * 全部展开/折叠
     */
    toggleRowExpansion() {
      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
    /**
     * 筛选结点
     */
    filterNode(value: any, data: any) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
  },
});
</script>
<style lang="less" scoped>
@import "../../styles/less/flow-tree.less";

:deep(.custom-tree-node) {
  width: calc(100% - 40px);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:deep(.custom-tree-node-left) {
  flex: 1;
  display: flex;
  align-items: center;
  max-width: calc(100% - 100px);
}
:deep(.custom-tree-node-left-text) {
  white-space: pre;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
}

:deep(.custom-tree-node-right) {
  max-width: 80px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-right: 8px;
}
.el-tree {
  --el-tree-node-content-height: 50px !important;
}
</style>
