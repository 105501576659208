<template>
  <!-- <el-header> 头部布局 </el-header> -->
  <el-header>
    <div
      class="el-header-left"
      :style="
        !isCollapse
          ? `width: calc(var(--left-menu-max-width) - 1px)`
          : `width: 49px; min-width: 49px;`
      "
    >
      <span
        v-if="info && info.custom && info.custom.logo"
        :title="
          info.custom.shop_title
            ? info.custom.shop_title
            : info.custom.shop_name
            ? info.custom.shop_name
            : ''
        "
      >
        <el-image
          :style="
            !isCollapse
              ? `max-width: 180px; width: 180px;max-height: 60px; height: 60px`
              : `max-width: 30px; width: 30px;max-height: 30px; height: 30px`
          "
          :src="info.custom.logo"
        />
      </span>
      <span
        v-if="
          info &&
          info.custom &&
          info.custom.logo == '' &&
          info.custom.shop_title
        "
        :title="info.custom.shop_title"
      >
        {{ info.custom.shop_title }}
      </span>
      <span
        v-if="
          info &&
          info.custom &&
          info.custom.logo == '' &&
          info.custom.shop_title == '' &&
          info.custom.shop_name
        "
        :title="info.custom.shop_name"
      >
        {{ info.custom.shop_name }}
      </span>
    </div>
    <div class="el-header-right">
      <div class="login-info">
        <div class="login-info-data login-info-data-left">
          <el-text class="shop-name">
            <span
              v-if="info && info.custom && info.custom.shop_title"
              :title="info.custom.shop_title"
            >
              {{ info.custom.shop_title }}
            </span>
            <span
              v-if="
                info &&
                info.custom &&
                info.custom.shop_title == '' &&
                info.custom.shop_name
              "
              :title="info.custom.shop_name"
            >
              {{ info.custom.shop_name }}
            </span>
          </el-text>
          <el-text
            class="shop-name"
            style="
              margin-left: 0px;
              width: calc(100% - var(--login-info-data-left-width) - 20px);
              max-width: calc(100% - var(--login-info-data-left-width) - 20px);
            "
          >
            <el-text class="text-div" style="margin-left: 10px">
              培训管理后台系统
            </el-text>
            <el-text class="text-border"> 标准版 </el-text>
            <el-text class="text-border"> 企业 </el-text>
            <el-text
              v-if="days > -1"
              class="text-border tip"
              :title="`试用期剩余：` + days + `天`"
            >
              <Warning class="warning-icon" />
              试用期剩余：{{ days }} 天
            </el-text>
            <el-text
              class="text-border tip"
              title="请您在 《企业设置》 填写补全企业及联系人信息"
            >
              <Warning class="warning-icon" />
              请您在 《企业设置》 填写补全企业及联系人信息
            </el-text>
          </el-text>
        </div>

        <div class="login-info-data login-info-data-right">
          <div class="help-button">
            <router-link :to="{ name: 'Help' }" v-tag="`a`" target="_blank">
              <el-button type="primary" @click="1"> 帮助中心 </el-button>
            </router-link>
          </div>
          <el-dropdown
            :hide-on-click="false"
            class="el-dropdown-p"
            placement="bottom-start"
            tabindex="logon-336458"
          >
            <el-link
              class="el-dropdown-link"
              style="line-height: normal !important"
            >
              <span style="width: 60px">欢迎您：</span>
              <OpenData
                v-if="info && info.user && info.user.userid"
                :type="`userName`"
                :openid="info.user.userid"
              />
            </el-link>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="getAdminInfo">
                  个人信息
                </el-dropdown-item>
                <el-dropdown-item @click="getAdminLog">
                  操作日志
                </el-dropdown-item>
                <el-dropdown-item divided @click="loginOut">
                  退出
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </el-header>
</template>

<script lang="ts">
import router from "@/router";
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";

import { OpenData } from "@/components";
import { initGetAgentConfig } from "@/utils/getAgerentConfig";
import { isNotEmpty } from "@/utils/helper";

import { getTrialDate } from "@/api/home";

export default defineComponent({
  name: "AppHeader",
  components: {
    OpenData,
  },
  emits: ["collapseMenuBtnClick"],
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      info: {} as any,
      days: -1,
    };
  },
  created() {
    this.initFunc();
  },
  methods: {
    /**
     * 初始化
     */
    initFunc() {
      if (localStorage.getItem("adminInfo")) {
        this.info = JSON.parse(localStorage.getItem("adminInfo") || "[]");
        initGetAgentConfig({ url: encodeURI(window.location.href) });

        this.getTrialDate();
      } else {
        this.info = {};
        this.removeLocalStorage();
      }
    },

    /**
     * 获取试用时间
     */
    async getTrialDate() {
      getTrialDate({}).then((res: any) => {
        if (isNotEmpty(res)) {
          this.days = res.data;
        }
      });
    },

    /**
     * 个人信息
     */
    getAdminInfo() {
      router.push({
        path: "/system/my-info",
      });
    },

    /**
     * 操作日志
     */
    getAdminLog() {
      router.push({
        path: "/system/operation-log",
      });
    },

    /**
     * 退出登录
     */
    removeLocalStorage() {
      /**
       * 删除token
       */
      localStorage.removeItem("token");
      localStorage.removeItem("adminInfo");
      ElMessage.error("登录态已过期，请重新登录！");
      router.push({
        path: "/login/login",
      });
    },

    /**
     * 退出登录
     */
    loginOut() {
      /**
       * 删除token
       */
      localStorage.removeItem("token");
      localStorage.removeItem("adminInfo");
      ElMessage.success("账号已退出");
      router.push({
        path: "/login/login",
      });
    },
  },
});
</script>

<style lang="less" scoped>
.el-header {
  background-color: var(--top-header-bg-color);
  color: var(--top-header-text-color);
  line-height: var(--top-tool-height);
  min-height: var(--top-tool-height);
  height: var(--top-tool-height);
  min-width: var(--top-tool-width);
  width: var(--top-tool-width);
  background: #ffffff;
  text-align: left;
  padding-left: 0px;
  display: flex;
  text-align: center;
  align-items: center;
  overflow: hidden;

  .el-header-left {
    min-width: 199px;
    width: calc(var(--left-menu-max-width) - 1px);
    max-width: calc(var(--left-menu-max-width) - 1px);

    min-height: var(--top-tool-height);
    height: var(--top-tool-height);
    max-height: var(--top-tool-height);
    background-color: #273043;
    font-weight: 800;
  }

  .el-header-left span {
    font-size: 22px;
    text-size-adjust: 100%;

    line-height: calc(var(--top-tool-height) / 2) !important;

    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
  }
  .el-header-right {
    min-width: 500px;
    width: calc(100% - var(--left-menu-max-width));
    height: var(--top-tool-height);

    .login-info {
      display: flex;
      // flex-flow: row-reverse wrap;
      justify-content: space-between;
      text-align: center;
      align-items: center;
      max-height: var(--top-tool-height);

      .text-border {
        margin-left: 10px;
        padding: 1px 2px;
        border: 1px solid #2a75ed;
        // border-radius: 4px;
        color: #2a75ed;
      }

      .login-info-data {
        width: auto;
        padding: 0 10px;
        font-size: 16px;
        height: 100%;
        max-height: var(--top-tool-height);
      }
      .login-info-data-left {
        overflow: hidden;
        display: flex;
        justify-content: space-between;

        .shop-name {
          display: inline-block;
          font-size: 20px;
          font-weight: bold;
          margin-left: 10px;
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .tip {
          position: relative;
          color: red;
          border-color: red;
        }
        .warning-icon {
          color: red;
          width: 15px;
          height: 15px;
          position: relative;
          bottom: -2px;
        }
      }

      .login-info-data-right {
        display: flex;
        width: auto;
        padding: 0 0 0 10px;
        font-size: 16px;
        height: 100%;
        border-left: 1px solid #cacaca;

        .help-button {
          height: var(--top-tool-height);
          display: inline-flex;
          margin-right: 10px;
        }

        .el-dropdown-p {
          height: var(--top-tool-height);
          display: inline-flex !important;
          align-items: center;
        }
        .el-dropdown-link {
          // height: var(--top-tool-height) !important;
          min-width: 120px;
          display: inline-block;
        }
        .el-dropdown-link:hover {
          color: #f57979;
        }
      }
    }
  }
}
</style>
