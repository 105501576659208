import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["element-loading-text"]
const _hoisted_2 = { class: "leftTree" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = {
  key: 0,
  style: {"display":"inline-flex","align-items":"center"}
}
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 1,
  style: {"display":"inline-flex","align-items":"center"}
}
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "rightTree" }
const _hoisted_9 = { class: "treeTit" }
const _hoisted_10 = { style: {"float":"right"} }
const _hoisted_11 = {
  class: "list leftList",
  style: {"width":"100%"}
}
const _hoisted_12 = { style: {"display":"inline-flex","align-items":"center"} }
const _hoisted_13 = ["title"]
const _hoisted_14 = { style: {"float":"right"} }
const _hoisted_15 = { style: {"display":"inline-flex","align-items":"center"} }
const _hoisted_16 = ["title"]
const _hoisted_17 = { style: {"float":"right"} }
const _hoisted_18 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search = _resolveComponent("search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_Folder = _resolveComponent("Folder")!
  const _component_OpenData = _resolveComponent("OpenData")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_CloseBold = _resolveComponent("CloseBold")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogDUTreeShow,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogDUTreeShow) = $event)),
    title: "选择成员",
    "close-on-click-modal": false,
    "destroy-on-close": true,
    class: "customWidth",
    width: "70%",
    "before-close": _ctx.onCancelDUTree
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.onSubmitDUTree,
          disabled: _ctx.disabledAll
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" 保存 ")
          ])),
          _: 1
        }, 8, ["onClick", "disabled"]),
        _createVNode(_component_el_button, { onClick: _ctx.onCancelDUTree }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "treeTransfer",
        "element-loading-text": _ctx.loadingText
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "treeTit" }, "待选择数据", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_input, {
              modelValue: _ctx.filterText,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
              placeholder: "请输入关键字",
              style: {"padding":"10px"},
              onKeyup: _withKeys(_ctx.searchDUTree, ["enter"])
            }, {
              suffix: _withCtx(() => [
                _createVNode(_component_el_icon, {
                  class: "el-input__icon",
                  onClick: _ctx.searchDUTree,
                  style: {"cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_search)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["modelValue", "onKeyup"]),
            _createVNode(_component_el_scrollbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_tree, {
                  ref: "treeLeft",
                  class: "flow-tree st-overflow-x st-overflow-y",
                  data: _ctx.dataLeft,
                  props: _ctx.defaultProps,
                  "node-key": _ctx.defaultProps.label,
                  "highlight-current": true,
                  "auto-expand-parent": true,
                  "check-strictly": "",
                  "show-checkbox": "",
                  onCheckChange: _ctx.handleCheckChangeDUTree
                }, {
                  default: _withCtx(({ node, data }) => [
                    (node.label > -1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          _createVNode(_component_Folder, { style: {"width":"20px","height":"20px","margin-right":"5px"} }),
                          _createElementVNode("span", {
                            title: node.label
                          }, [
                            _createVNode(_component_OpenData, {
                              type: `departmentName`,
                              openid: node.label
                            }, null, 8, ["openid"])
                          ], 8, _hoisted_5)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          _createVNode(_component_el_avatar, {
                            src: data.avatar,
                            style: {"width":"20px","height":"20px","min-height":"20px","margin-right":"5px"}
                          }, null, 8, ["src"]),
                          _createElementVNode("span", {
                            title: data.account
                          }, [
                            _createVNode(_component_OpenData, {
                              type: `userName`,
                              openid: data.account
                            }, null, 8, ["openid"])
                          ], 8, _hoisted_7)
                        ]))
                  ]),
                  empty: _withCtx(() => [
                    _createVNode(_component_el_empty, { description: "没有数据！~" })
                  ]),
                  _: 1
                }, 8, ["data", "props", "node-key", "onCheckChange"])
              ]),
              _: 1
            })
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "btnDiv" }, null, -1)),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createTextVNode(" 已选择数据（ " + _toDisplayString(_ctx.departmentsData.length + _ctx.usersData.length) + " ） ", 1),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_button, {
                type: "primary",
                disabled: _ctx.disabledAll,
                onClick: _ctx.onEmptyDUTree
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" 清空 ")
                ])),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_el_scrollbar, { style: {"width":"100%"} }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.departmentsData, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "listItem"
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_Folder, { style: {"width":"20px","height":"20px","margin-right":"5px"} }),
                      _createElementVNode("span", { title: item }, [
                        _createVNode(_component_OpenData, {
                          type: `departmentName`,
                          openid: item
                        }, null, 8, ["openid"])
                      ], 8, _hoisted_13)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_el_button, {
                        disabled: _ctx.disabledAll,
                        onClick: ($event: any) => (_ctx.handleDeleteDUTree(item))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_CloseBold)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ])
                  ]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersData, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "listItem"
                  }, [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_el_avatar, {
                        src: item.avatar,
                        style: {"width":"20px","height":"20px","min-height":"20px","margin-right":"5px"}
                      }, null, 8, ["src"]),
                      _createElementVNode("span", {
                        title: item.userid
                      }, [
                        _createVNode(_component_OpenData, {
                          type: `userName`,
                          openid: item.userid
                        }, null, 8, ["openid"])
                      ], 8, _hoisted_16)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_el_button, {
                        disabled: _ctx.disabledAll,
                        onClick: ($event: any) => (_ctx.handleDeleteDUTree(item))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_CloseBold)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ])
                  ]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ])
      ], 8, _hoisted_1)), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}