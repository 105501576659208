import { useAxios } from "@/utils/useAxios";

const request = useAxios();

/**
 * 客户已授权课程列表
 * @param params
 * @returns
 */
export const getAuthColumnTree = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/shop-column/auth-column-tree",
    params,
  });

  return res && res.data;
};
