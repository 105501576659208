import { resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "top-start",
    width: _ctx.width,
    trigger: "hover"
  }, {
    reference: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
        style: _normalizeStyle(_ctx.iconStyle)
      }, null, 8, ["style"]))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_alert, {
        "show-icon": _ctx.alertShowIcon,
        type: _ctx.alertType,
        closable: _ctx.alertClosable,
        effect: _ctx.alertEffect
      }, {
        title: _withCtx(() => [
          _createElementVNode("span", { innerHTML: _ctx.content }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["show-icon", "type", "closable", "effect"])
    ]),
    _: 1
  }, 8, ["width"]))
}