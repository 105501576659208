import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"max-width":"500px"} }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExamTypeTreeSelect = _resolveComponent("ExamTypeTreeSelect")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.closeEditPage,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.closeEditPage) = $event)),
    title: _ctx.openEditPage == 'add' ? '新增考试分类' : '编辑考试分类',
    "close-on-click-modal": false,
    "destroy-on-close": true,
    width: "50%",
    style: {"overflow-y":"auto"}
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.openEditPage == 'edit')
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              onClick: _ctx.onSubmit,
              disabled: _ctx.clickFlag
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" 立即修改 ")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]))
          : (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              type: "primary",
              onClick: _ctx.onSubmit,
              disabled: _ctx.clickFlag
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" 立即添加 ")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"])),
        _createVNode(_component_el_button, { onClick: _ctx.onCancel }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_form, {
          ref: "multipleForm",
          model: _ctx.formInfo,
          rules: _ctx.rules,
          "label-position": _ctx.labelPosition,
          "label-width": "130px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "选择上级分类：",
              prop: "pid"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ExamTypeTreeSelect, {
                  defaultExpandedKeys: [_ctx.formInfo.pid],
                  filterable: false,
                  onGetValue: _ctx.handleChangeTypeSelect,
                  style: { width: '100%' }
                }, null, 8, ["defaultExpandedKeys", "onGetValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "分类名称：",
              prop: "title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formInfo.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInfo.title) = $event)),
                  placeholder: "请输入分类名称",
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-position"])
      ])), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}