import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["element-loading-text"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search = _resolveComponent("search")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_Folder = _resolveComponent("Folder")!
  const _component_OpenData = _resolveComponent("OpenData")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    "element-loading-text": _ctx.loadingText,
    style: {"height":"100%"}
  }, [
    (_ctx.showSerach)
      ? (_openBlock(), _createBlock(_component_el_input, {
          key: 0,
          modelValue: _ctx.filterText,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterText) = $event)),
          disabled: _ctx.filterDisabled,
          placeholder: "请输入部门名称",
          style: {"padding":"10px"}
        }, {
          suffix: _withCtx(() => [
            _createVNode(_component_el_icon, { class: "el-input__icon" }, {
              default: _withCtx(() => [
                _createVNode(_component_search)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "disabled"]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_tree, {
      ref: "treeData",
      class: "flow-tree st-overflow-x st-overflow-y",
      disabled: _ctx.disabled,
      style: _normalizeStyle(_ctx.styles),
      data: _ctx.listData,
      props: _ctx.propsRule,
      "node-key": "department_id",
      "highlight-current": _ctx.highlightCurrent,
      "default-expanded-keys": _ctx.defaultExpandedKeys,
      "auto-expand-parent": _ctx.autoExpandParent,
      "show-checkbox": _ctx.showCheckbox,
      "check-strictly": _ctx.checkStrictly,
      "default-checked-keys": _ctx.defaultCheckedKeys,
      "filter-node-method": _ctx.filterNode,
      "expand-on-click-node": _ctx.expandNoClickNode,
      filterable: _ctx.filterable,
      onNodeClick: _ctx.handleNodeClick,
      onNodeContextmenu: _ctx.handleNodeContextmenu,
      onCheck: _ctx.handleCheck
    }, {
      default: _withCtx(({ node, data }) => [
        _createVNode(_component_Folder, { style: {"width":"20px","height":"20px","margin-right":"5px"} }),
        _createElementVNode("span", null, [
          _createVNode(_component_el_tooltip, {
            placement: "top",
            effect: "dark"
          }, {
            content: _withCtx(() => [
              _createTextVNode(" ID: " + _toDisplayString(data.id) + " ", 1),
              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" DID: " + _toDisplayString(data.department_id) + " ", 1),
              _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
              (data.department_id == -1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.department_name), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createVNode(_component_OpenData, {
                      type: `departmentName`,
                      openid: node.label
                    }, null, 8, ["openid"])
                  ]))
            ]),
            default: _withCtx(() => [
              (data.department_id == -1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(data.department_name), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createVNode(_component_OpenData, {
                      type: `departmentName`,
                      openid: node.label
                    }, null, 8, ["openid"])
                  ]))
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      empty: _withCtx(() => [
        _createVNode(_component_el_empty, { description: "没有数据！~" })
      ]),
      _: 1
    }, 8, ["disabled", "style", "data", "props", "highlight-current", "default-expanded-keys", "auto-expand-parent", "show-checkbox", "check-strictly", "default-checked-keys", "filter-node-method", "expand-on-click-node", "filterable", "onNodeClick", "onNodeContextmenu", "onCheck"])
  ], 8, _hoisted_1)), [
    [_directive_loading, _ctx.loading]
  ])
}