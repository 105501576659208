<!-- 新增/修改题目分类页面 -->
<template>
  <el-dialog
    v-model="closeEditPage"
    :title="openEditPage == 'add' ? '新增考试分类' : '编辑考试分类'"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    width="50%"
    style="overflow-y: auto"
  >
    <div v-loading="loading" style="max-width: 500px">
      <el-form
        ref="multipleForm"
        :model="formInfo"
        :rules="rules"
        :label-position="labelPosition"
        label-width="130px"
      >
        <el-form-item label="选择上级分类：" prop="pid">
          <!-- 题目分类树下拉框 -->
          <ExamTypeTreeSelect
            :defaultExpandedKeys="[formInfo.pid]"
            :filterable="false"
            @getValue="handleChangeTypeSelect"
            :style="{ width: '100%' }"
          />
        </el-form-item>
        <el-form-item label="分类名称：" prop="title">
          <el-input
            v-model="formInfo.title"
            placeholder="请输入分类名称"
            clearable
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          v-if="openEditPage == 'edit'"
          type="primary"
          @click="onSubmit"
          :disabled="clickFlag"
        >
          立即修改
        </el-button>
        <el-button
          v-else
          type="primary"
          @click="onSubmit"
          :disabled="clickFlag"
        >
          立即添加
        </el-button>
        <el-button @click="onCancel">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

import { ExamTypeTreeSelect } from "@/components";
import { isNotEmpty } from "@/utils/helper";

import { getTypeInfo, postTypeEdit } from "@/api/exam";

export default defineComponent({
  /**
   * 使用组件
   */
  components: {
    ExamTypeTreeSelect,
  },
  name: "TypeEditView",

  /**
   * 接收父页面传递的值
   */
  props: ["typeLists", "typeRowId", "typeRowPid", "openEditPage"],

  data() {
    return {
      /**
       * loading.加载 true显示 false不显示 默认false
       * closeEditPage.页面显示  true显示 false不显示 默认false
       * clickFlag.设置按钮不可点击（disable: false）
       * labelPosition.输入内容文字提示位置
       */
      loading: false,
      closeEditPage: false,
      clickFlag: false,
      labelPosition: "right",

      /**
       * 表单填写规则
       */
      rules: {
        title: {
          required: true,
          message: "请输入题库分类名称",
          trigger: "blur",
        },
        pid: {
          required: true,
          message: "请选择上级分类",
          trigger: "blur",
        },
      },
      /**
       * 输入的内容
       */
      formInfo: {
        id: 0,
        title: "",
        pid: 0,
      },
    };
  },
  methods: {
    initFunEdit() {
      this.showTrueOrFalse(true);

      /**
       * 初始化参数
       */
      this.formInfo = {
        id: 0,
        title: "",
        pid: Number(this.typeRowId ?? 0),
      };

      if (this.openEditPage == "edit") {
        this.formInfo.pid = Number(this.typeRowPid ?? 0);

        this.initGetTypeInfo();
      }

      this.showTrueOrFalse(false);
      this.closeEditPage = true;
    },

    /**
     * 获取题库分类详情接口
     */
    async initGetTypeInfo() {
      getTypeInfo({ id: this.typeRowId }).then((res: any) => {
        if (isNotEmpty(res)) {
          this.formInfo = res.data;
        }
      });
    },

    /**
     * 编辑接口
     */
    async subostTypeEdit(params: any) {
      postTypeEdit(params).then((res: any) => {
        if (100 == res.errcode) {
          ElMessage.success(res.msg);
          this.$emit("searchWhere");
        }

        setTimeout(() => {
          this.closeEditPage = false;
        }, 1000);
      });
    },

    /**
     * 选择分类后，回调方法
     */
    handleChangeTypeSelect(pid: any) {
      this.formInfo.pid = pid ?? "";
    },

    /**
     * 点击保存，调用ajax保存数据后，使用父级页面方法关闭当前页
     */
    onSubmit() {
      this.showTrueOrFalse(true);

      interface obj {
        [id: string]: any;
      }

      /**
       * 定义传递的参数
       */
      let params: obj = {
        title: this.formInfo.title.trim(),
        pid: this.formInfo.pid,
      };

      if (!params.title) {
        this.showTrueOrFalse(false, "请输入题库分类名称");
        return false;
      }

      /**
       * 存在数据并且 id存在 为修改  不存在为添加
       */
      if (this.typeRowId && this.formInfo.id) {
        params.id = this.formInfo.id;
      }

      this.subostTypeEdit(params);
      this.showTrueOrFalse(false);
    },

    /**
     * 点击取消，使用父级页面方法关闭当前页
     */
    onCancel() {
      this.$emit("update:typeRowId", 0);
      this.$emit("update:openEditPage", "");
      this.closeEditPage = false;
    },

    /**
     * 显示还是隐藏
     * @param bools true 显示限制，false 不显示不限制
     * @param msg 提示消息
     */
    showTrueOrFalse(bools = true, msg = "") {
      if (bools) {
        this.loading = bools;
        this.clickFlag = bools;
      } else {
        /**
         * 消息提示
         */
        if (msg) {
          ElMessage.error(msg);
        }
        /**
         * 隐藏全局遮蔽
         */
        setTimeout(() => {
          this.loading = bools;
        }, 500);

        /**
         * 取消禁用点击
         */
        setTimeout(() => {
          this.clickFlag = bools;
        }, 2000);
      }
    },
  },
});
</script>
<style>
.el-dialog__body {
  padding: 0px var(--el-dialog-padding-primary) var(--el-dialog-padding-primary);
}
.el-dialog__footer {
  border-top: 1px solid #ebebeb;
}
</style>
<style lang="less"></style>
