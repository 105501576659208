import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.less";
import "@/styles/global.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "default-passive-events";

const app = createApp(App);

app.use(store).use(router).use(ElementPlus);

/**
 * 注册echarts
 */
import Echarts from "vue-echarts";
import "echarts";
import "echarts/theme/shine"; // 引入echart 主题(多种主题可使用)
import "echarts/theme/cool";
app.component("v-chart", Echarts);

/**
 * 注册element-plus的图标
 */
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mount("#app");
