import { useAxios } from "@/utils/useAxios";

const request = useAxios();

/**
 * 用户列表有分页
 * @param params
 * @returns
 */
export const getUserList = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/user/list", data });

  return res && res.data;
};

/**
 * 用户信息
 * @param params
 * @returns
 */
export const getUserInfo = async (params: any): Promise<Response> => {
  const res = await request.get({ url: "/user/info", params });

  return res && res.data;
};

/**
 * 获取企业已授权的课程
 * @param params
 * @returns
 */
export const getShopCourseList = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/user/shop-course-list",
    params,
  });

  return res && res.data;
};

/**
 * 获取用户学习视频详情
 * @param params
 * @returns
 */
export const getWatchList = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/user/watch-list",
    params,
  });

  return res && res.data;
};

/**
 * 获取用户学习考试详情
 * @param params
 * @returns
 */
export const getRecords = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/user/records",
    params,
  });

  return res && res.data;
};

/**
 *  获取用户学习考试详情-导出
 */
export const exportRecords = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/user/export-records",
    data,
  });

  return res && res.data;
};

/**
 *  获取用户积分列表
 */
export const getUserCreditList = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/user-credit/list",
    params,
  });

  return res && res.data;
};

/**
 *  更新用户学分
 */
export const postUserCreditRenew = async (data: any): Promise<Response> => {
  const res = await request.post({
    url: "/user-credit/renew-credit",
    data,
  });

  return res && res.data;
};
