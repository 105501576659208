import { useAxios } from "@/utils/useAxios";

const request = useAxios();

/**
 * 图片上传
 * @param data
 * @returns
 */
export const postUploadImage = async (data: any): Promise<Response> => {
  const res = await request.post({ url: "/uploads/upload-image", data });

  return res && res.data;
};

/**
 * 腾讯云云存储配置
 * @param data
 * @returns
 */
export const tencentCloudCosConfig = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/uploads/tencent-cloud-cos-config",
    params,
  });

  return res && res.data;
};
