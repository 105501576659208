import { useAxios } from "@/utils/useAxios";

const request = useAxios();

/**
 * 部门列表
 */
export const getDepartmentList = async (params: any): Promise<Response> => {
  const res = await request.get({
    url: "/department/list",
    params,
  });

  return res && res.data;
};

/**
 * 学员和部门列表
 */
export const getUserAndDepartmentList = async (
  params: any
): Promise<Response> => {
  const res = await request.get({
    url: "/department/user-and-department-list",
    params,
  });

  return res && res.data;
};
