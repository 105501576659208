import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_image_viewer = _resolveComponent("el-image-viewer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      class: "upload-image-uploader",
      "show-file-list": false,
      "on-success": _ctx.handleUploadImageSuccess,
      "before-upload": _ctx.beforeUploadImageUpload,
      "http-request": _ctx.onSubmit,
      disabled: _ctx.imgDisable
    }, {
      default: _withCtx(() => [
        (_ctx.imageUrl != '' || _ctx.selectImage != '')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: `https:` + (_ctx.imageUrl != '' ? _ctx.imageUrl : _ctx.selectImage),
              class: "upload-image",
              style: _normalizeStyle(_ctx.style)
            }, null, 12, _hoisted_1))
          : (_openBlock(), _createBlock(_component_el_icon, {
              key: 1,
              class: "upload-image-uploader-icon"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Plus)
              ]),
              _: 1
            }))
      ]),
      _: 1
    }, 8, ["on-success", "before-upload", "http-request", "disabled"]),
    _cache[2] || (_cache[2] = _createTextVNode("   ")),
    _createVNode(_component_el_button, { onClick: _ctx.handlePictureCardPreview }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" 图片预览 ")
      ])),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.dialogVisible)
      ? (_openBlock(), _createBlock(_component_el_image_viewer, {
          key: 0,
          "url-list": [`https:` + _ctx.dialogImageUrl],
          "preview-teleported": "",
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = false)),
          "z-index": "999999"
        }, null, 8, ["url-list"]))
      : _createCommentVNode("", true)
  ], 64))
}