<!-- 导出指定数据 -->
<template>
  <el-dialog
    v-model="isExportShowPage"
    title="导出"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    class="customWidth"
    width="50%"
  >
    <div class="main-div" style="padding: 0" v-loading="loadingExport">
      <el-alert
        v-if="clickAlert"
        title=""
        type="success"
        effect="dark"
        center
        style="margin: 10px 0px"
        @close="clickAlert = false"
      >
        <template #title>
          <span>导出完毕，您可前往下载中心中查看&nbsp;&nbsp;&nbsp;</span>
          <router-link :to="{ name: 'Downloads' }" v-tag="`a`" target="_blank">
            <el-button type="primary" plain> 立即前往 </el-button>
          </router-link>
        </template>
      </el-alert>

      <el-form
        ref="multipleForm"
        :label-position="`right`"
        label-width="100px"
        style="max-width: 700px"
      >
        <el-form-item label="导出字段" v-if="isSelectFields">
          <el-radio-group v-model="checkFields">
            <el-radio :label="1" border>默认字段</el-radio>
            <el-radio :label="2" border>指定字段</el-radio>
            <PopoverAlert
              :width="300"
              :icon="`QuestionFilled`"
              :iconStyle="{
                width: '20px',
                marginLeft: '-20px',
                color: 'red',
              }"
              :alertType="`warning`"
              :content="`
                <div>指定字段将按照字段选择顺序排序</div>
              `"
            />
          </el-radio-group>

          <el-checkbox-group v-model="selectedFields" v-if="checkFields == 2">
            <el-checkbox
              v-for="item in checkboxOptions"
              :key="item"
              :label="item"
            >
              {{ item.title }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="导出部门" v-if="isSelectDepartment">
          <el-radio-group v-model="checkDepatment">
            <el-radio :label="1" border v-if="allDepartment">
              所有部门
            </el-radio>
            <el-radio :label="2" border>指定部门</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div
        class="st-overflow-x st-overflow-y"
        style="max-height: 400px"
        v-if="isSelectDepartment"
      >
        <!-- 部门树 -->
        <DepartmentTree
          v-if="checkDepatment == 2"
          :showCheckbox="true"
          :expandNoClickNode="true"
          :defaultPush="false"
          :checkStrictly="checkStrictly"
          @handleTreeChange="handleChangeDepartmentFunc"
        />
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="onSubmit"> 导出 </el-button>
        <el-button @click="onCancel">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" steup>
import { defineComponent, PropType } from "vue";
import { ElMessage } from "element-plus";
import { DepartmentTree, PopoverAlert } from "@/components";

export default defineComponent({
  /**
   * 使用组件
   */
  components: {
    DepartmentTree,
    PopoverAlert,
  },
  name: "SelectExportData",

  /**
   * 接收父页面传递的值
   */
  emits: [
    /**
     * id 导出的id
     * isSelectFields 是否自定义导出字段 默认false
     * checkboxOptions 自定义导出标题 和 字段 默认[]
     * isSelectDepartment 是否选择导出部门 默认true
     * getSetExportData 获取设置导出的数据
     * checkStrictly 是否关联子部门 默认true
     * allDepartment 是否显示所有部门 默认true
     */
    "id",
    "isSelectFields",
    "checkboxOptions",
    "isSelectDepartment",
    "getSetExportData",
    "checkStrictly",
    "allDepartment",
  ],

  props: {
    id: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    isSelectFields: {
      type: Boolean,
      default: false,
    },
    isSelectDepartment: {
      type: Boolean,
      default: true,
    },
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    allDepartment: {
      type: Boolean,
      default: true,
    },
    checkboxOptions: {
      type: Array as PropType<Array<any>>,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      /**
       * 是否显示该页面
       */
      isExportShowPage: false,
      loadingExport: false,
      clickAlert: false,

      /**
       * 选择导出部门范围
       */
      checkDepatment: 1 as any,

      /**
       * 选择的部门id
       */
      departmentIds: 0 as any,

      /**
       * 选择导出字段
       */
      checkFields: 1 as any,

      /**
       * 选择导出字段
       */
      selectedFields: [] as any,
    };
  },
  created() {
    this.initSelectExportFun();
  },
  methods: {
    initSelectExportFun() {
      this.loadingExport = true;
      this.isExportShowPage = true;
      this.checkDepatment = 1;
      this.departmentIds = 0;
      this.checkFields = 1;
      this.selectedFields = [];
      setTimeout(() => {
        this.loadingExport = false;
      }, 500);
    },

    /**
     * 点击部门
     */
    handleChangeDepartmentFunc(departmentIds: any) {
      this.departmentIds = departmentIds;
    },

    /**
     * 点击保存
     */
    onSubmit() {
      this.loadingExport = true;

      if (
        this.isSelectFields &&
        this.selectedFields &&
        this.checkFields == 2 &&
        this.selectedFields.length == 0
      ) {
        ElMessage.error("请选择要导出的字段!~");
        setTimeout(() => {
          this.loadingExport = false;
        }, 500);
        return false;
      }

      if (
        this.isSelectDepartment &&
        this.checkDepatment == 2 &&
        !this.departmentIds
      ) {
        ElMessage.error("请选择部门!~");
        setTimeout(() => {
          this.loadingExport = false;
        }, 500);
        return false;
      }

      /**
       * 定义传递的参数
       */
      let params: any = {
        export: 1,
      };

      /**
       * 是否设置了导出数据的id
       */
      if (this.id) {
        params.id = this.id;
      }

      if (this.isSelectFields && this.selectedFields && this.checkFields == 2) {
        params.fields = this.selectedFields;
      }

      if (this.isSelectDepartment && this.checkDepatment == 2) {
        params.departmentIds = this.departmentIds;
      }

      this.$emit("getSetExportData", params);
    },

    /**
     * 点击取消，使用父级页面方法关闭当前页
     */
    onCancel() {
      this.isExportShowPage = false;
    },
  },
});
</script>
<style>
.el-dialog__body {
  padding: 0px var(--el-dialog-padding-primary) var(--el-dialog-padding-primary);
}
.el-dialog__footer {
  border-top: 1px solid #ebebeb;
}
</style>
<style lang="less" scoped>
:deep(.main-div) {
  min-width: 700px;
}

:deep(.el-alert .el-alert__close-btn) {
  font-size: 24px;
}
</style>
