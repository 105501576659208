import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "el-header-right" }
const _hoisted_5 = { class: "login-info" }
const _hoisted_6 = { class: "login-info-data login-info-data-left" }
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "login-info-data login-info-data-right" }
const _hoisted_10 = { class: "help-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OpenData = _resolveComponent("OpenData")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_header = _resolveComponent("el-header")!
  const _directive_tag = _resolveDirective("tag")!

  return (_openBlock(), _createBlock(_component_el_header, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "el-header-left",
        style: _normalizeStyle(
        !_ctx.isCollapse
          ? `width: calc(var(--left-menu-max-width) - 1px)`
          : `width: 49px; min-width: 49px;`
      )
      }, [
        (_ctx.info && _ctx.info.custom && _ctx.info.custom.logo)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              title: 
          _ctx.info.custom.shop_title
            ? _ctx.info.custom.shop_title
            : _ctx.info.custom.shop_name
            ? _ctx.info.custom.shop_name
            : ''
        
            }, [
              _createVNode(_component_el_image, {
                style: _normalizeStyle(
            !_ctx.isCollapse
              ? `max-width: 180px; width: 180px;max-height: 60px; height: 60px`
              : `max-width: 30px; width: 30px;max-height: 30px; height: 30px`
          ),
                src: _ctx.info.custom.logo
              }, null, 8, ["style", "src"])
            ], 8, _hoisted_1))
          : _createCommentVNode("", true),
        (
          _ctx.info &&
          _ctx.info.custom &&
          _ctx.info.custom.logo == '' &&
          _ctx.info.custom.shop_title
        )
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              title: _ctx.info.custom.shop_title
            }, _toDisplayString(_ctx.info.custom.shop_title), 9, _hoisted_2))
          : _createCommentVNode("", true),
        (
          _ctx.info &&
          _ctx.info.custom &&
          _ctx.info.custom.logo == '' &&
          _ctx.info.custom.shop_title == '' &&
          _ctx.info.custom.shop_name
        )
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              title: _ctx.info.custom.shop_name
            }, _toDisplayString(_ctx.info.custom.shop_name), 9, _hoisted_3))
          : _createCommentVNode("", true)
      ], 4),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_text, { class: "shop-name" }, {
              default: _withCtx(() => [
                (_ctx.info && _ctx.info.custom && _ctx.info.custom.shop_title)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      title: _ctx.info.custom.shop_title
                    }, _toDisplayString(_ctx.info.custom.shop_title), 9, _hoisted_7))
                  : _createCommentVNode("", true),
                (
                _ctx.info &&
                _ctx.info.custom &&
                _ctx.info.custom.shop_title == '' &&
                _ctx.info.custom.shop_name
              )
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      title: _ctx.info.custom.shop_name
                    }, _toDisplayString(_ctx.info.custom.shop_name), 9, _hoisted_8))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_text, {
              class: "shop-name",
              style: {"margin-left":"0px","width":"calc(100% - var(--login-info-data-left-width) - 20px)","max-width":"calc(100% - var(--login-info-data-left-width) - 20px)"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_text, {
                  class: "text-div",
                  style: {"margin-left":"10px"}
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" 培训管理后台系统 ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_text, { class: "text-border" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" 标准版 ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_text, { class: "text-border" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" 企业 ")
                  ])),
                  _: 1
                }),
                (_ctx.days > -1)
                  ? (_openBlock(), _createBlock(_component_el_text, {
                      key: 0,
                      class: "text-border tip",
                      title: `试用期剩余：` + _ctx.days + `天`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Warning, { class: "warning-icon" }),
                        _createTextVNode(" 试用期剩余：" + _toDisplayString(_ctx.days) + " 天 ", 1)
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_text, {
                  class: "text-border tip",
                  title: "请您在 《企业设置》 填写补全企业及联系人信息"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Warning, { class: "warning-icon" }),
                    _cache[3] || (_cache[3] = _createTextVNode(" 请您在 《企业设置》 填写补全企业及联系人信息 "))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                to: { name: 'Help' },
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: ($event: any) => (1)
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" 帮助中心 ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })), [
                [_directive_tag, `a`]
              ])
            ]),
            _createVNode(_component_el_dropdown, {
              "hide-on-click": false,
              class: "el-dropdown-p",
              placement: "bottom-start",
              tabindex: "logon-336458"
            }, {
              dropdown: _withCtx(() => [
                _createVNode(_component_el_dropdown_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_dropdown_item, { onClick: _ctx.getAdminInfo }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" 个人信息 ")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_el_dropdown_item, { onClick: _ctx.getAdminLog }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" 操作日志 ")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_el_dropdown_item, {
                      divided: "",
                      onClick: _ctx.loginOut
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" 退出 ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_link, {
                  class: "el-dropdown-link",
                  style: {"line-height":"normal !important"}
                }, {
                  default: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createElementVNode("span", { style: {"width":"60px"} }, "欢迎您：", -1)),
                    (_ctx.info && _ctx.info.user && _ctx.info.user.userid)
                      ? (_openBlock(), _createBlock(_component_OpenData, {
                          key: 0,
                          type: `userName`,
                          openid: _ctx.info.user.userid
                        }, null, 8, ["openid"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}