<!-- 部门和成员页面 -->
<template>
  <el-dialog
    v-model="dialogDUTreeShow"
    title="选择成员"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    class="customWidth"
    width="70%"
    :before-close="onCancelDUTree"
  >
    <div
      class="treeTransfer"
      v-loading="loading"
      :element-loading-text="loadingText"
    >
      <div class="leftTree">
        <div class="treeTit">待选择数据</div>
        <div class="list">
          <el-input
            v-model="filterText"
            placeholder="请输入关键字"
            style="padding: 10px"
            @keyup.enter="searchDUTree"
          >
            <template #suffix>
              <el-icon
                class="el-input__icon"
                @click="searchDUTree"
                style="cursor: pointer"
              >
                <search />
              </el-icon>
            </template>
          </el-input>
          <el-scrollbar>
            <el-tree
              ref="treeLeft"
              class="flow-tree st-overflow-x st-overflow-y"
              :data="dataLeft"
              :props="defaultProps"
              :node-key="defaultProps.label"
              :highlight-current="true"
              :auto-expand-parent="true"
              check-strictly
              show-checkbox
              @check-change="handleCheckChangeDUTree"
            >
              <template v-slot="{ node, data }">
                <span
                  v-if="node.label > -1"
                  style="display: inline-flex; align-items: center"
                >
                  <Folder
                    style="width: 20px; height: 20px; margin-right: 5px"
                  />
                  <span :title="node.label">
                    <OpenData :type="`departmentName`" :openid="node.label" />
                  </span>
                </span>
                <span v-else style="display: inline-flex; align-items: center">
                  <el-avatar
                    :src="data.avatar"
                    style="
                      width: 20px;
                      height: 20px;
                      min-height: 20px;
                      margin-right: 5px;
                    "
                  />
                  <span :title="data.account">
                    <OpenData :type="`userName`" :openid="data.account" />
                  </span>
                </span>
              </template>
              <template v-slot:empty>
                <el-empty description="没有数据！~"></el-empty>
              </template>
            </el-tree>
          </el-scrollbar>
        </div>
      </div>
      <div class="btnDiv"></div>
      <div class="rightTree">
        <div class="treeTit">
          已选择数据（ {{ departmentsData.length + usersData.length }} ）
          <div style="float: right">
            <el-button
              type="primary"
              :disabled="disabledAll"
              @click="onEmptyDUTree"
            >
              清空
            </el-button>
          </div>
        </div>
        <div class="list leftList" style="width: 100%">
          <el-scrollbar style="width: 100%">
            <div
              v-for="(item, index) in departmentsData"
              :key="index"
              class="listItem"
            >
              <div style="display: inline-flex; align-items: center">
                <Folder style="width: 20px; height: 20px; margin-right: 5px" />
                <span :title="item">
                  <OpenData :type="`departmentName`" :openid="item" />
                </span>
              </div>
              <div style="float: right">
                <el-button
                  :disabled="disabledAll"
                  @click="handleDeleteDUTree(item)"
                >
                  <el-icon><CloseBold /></el-icon>
                </el-button>
              </div>
            </div>
            <div
              v-for="(item, index) in usersData"
              :key="index"
              class="listItem"
            >
              <div style="display: inline-flex; align-items: center">
                <el-avatar
                  :src="item.avatar"
                  style="
                    width: 20px;
                    height: 20px;
                    min-height: 20px;
                    margin-right: 5px;
                  "
                />
                <span :title="item.userid">
                  <OpenData :type="`userName`" :openid="item.userid" />
                </span>
              </div>
              <div style="float: right">
                <el-button
                  :disabled="disabledAll"
                  @click="handleDeleteDUTree(item)"
                >
                  <el-icon><CloseBold /></el-icon>
                </el-button>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmitDUTree"
          :disabled="disabledAll"
        >
          保存
        </el-button>
        <el-button @click="onCancelDUTree">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" steup>
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

import { OpenData } from "@/components";
import { isNotEmpty } from "@/utils/helper";

import { getUserAndDepartmentList, getDepartmentList } from "@/api/department";
import { getUserList } from "@/api/user";

import { initGetAgentConfig } from "@/utils/getAgerentConfig";
import { recArrTree } from "@/utils/function/arrayFunction";

export default defineComponent({
  components: {
    OpenData,
  },
  name: "DepartmentUserTree",
  emits: [
    "openDUTree",
    "disabledOpt",
    "disabledAll",
    "userIds",
    "departmentsIds",
    "handleChangeDUTreeFunc",
  ],

  props: {
    disabledOpt: {
      type: String,
      default: () => {
        return "";
      },
    },
    disabledAll: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    openDUTree: {
      type: String,
      default: () => {
        return "dialogDUTreeView";
      },
    },
    userIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    departmentsIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      dialogDUTreeShow: false,

      loading: false,
      loadingText: "加载中...",

      defaultProps: {
        value: "parentid",
        label: "department_id",
        children: "children",
        disabled: "disabled" as any,
      },

      data: [] as any,
      dataLeft: [] as any,
      filterText: "",

      usersData: [] as any,
      departmentsData: [] as any,
      defaultCheckedKeys: [] as any,
      departmentList: [] as any,
      userList: [] as any,
    };
  },
  methods: {
    /**
     * 初始化
     */
    initFunDUTree() {
      this.loadingText = "加载中...";
      this.setDUTreeShowTrueOrFalse(true);

      /**
       * 全部禁止选择
       */
      if (this.disabledAll === true) {
        this.defaultProps = {
          value: "parentid",
          label: "department_id",
          children: "children",
          disabled: function (data: any) {
            return !data.disabled;
          },
        };
      }

      /**
       * 设置默认值不能选中的范围
       */
      if (this.disabledOpt != "" && this.disabledAll === false) {
        if (this.disabledOpt == "department") {
          this.defaultProps = {
            value: "parentid",
            label: "department_id",
            children: "children",
            disabled: function (data: any) {
              if (!data.account) {
                return !data.disabled;
              } else {
                return data.disabled;
              }
            },
          };
        }

        if (this.disabledOpt == "user") {
          this.defaultProps = {
            value: "parentid",
            label: "department_id",
            children: "children",
            disabled: function (data: any) {
              if (data.account) {
                return !data.disabled;
              } else {
                return data.disabled;
              }
            },
          };
        }
      }

      /**
       * 初始化参数
       */
      this.data = [];
      this.dataLeft = [];
      this.filterText = "";
      this.usersData = this.userIds;
      this.departmentsData = this.departmentsIds;
      this.defaultCheckedKeys = [];

      // this.initGetDUTreeList({
      //   alias: 1,
      // });
      this.searchDUTree();
      this.dialogDUTreeShow = true;
      initGetAgentConfig({ url: encodeURI(window.location.href) });
    },

    /**
     * 搜索
     */
    searchDUTree() {
      this.loadingText = "加载数据中...";
      this.setDUTreeShowTrueOrFalse(true);

      let params = {
        searchName: this.filterText,
        limit: 0,
        page: 0,
        is_delete: 0,
      };

      // this.initGetDUTreeList(params);
      this.getDepartmentList(params);
    },

    async getDepartmentList(parameter: any) {
      this.departmentList = [];
      getDepartmentList(parameter).then((res: any) => {
        if (isNotEmpty(res)) {
          this.departmentList = res.data;
        }

        this.getUserList(parameter);
      });
    },
    async getUserList(parameter: any) {
      this.userList = [];
      getUserList(parameter).then((res: any) => {
        if (isNotEmpty(res)) {
          let userList: any = res.data;

          userList.forEach((row: any) => {
            let data = {
              department_ids: row.department_ids,
              department_id: row.account,
              parentid: 0,
              account: row.account,
              avatar: row.avatar,
            };

            this.userList.push(data);
          });
        }

        this.setData();
      });
    },
    setData() {
      this.loadingText = "解析数据中...";

      this.$nextTick(() => {
        console.log("解析数据中");
        console.log("time", new Date().getTime());

        let dataLeft = [] as any;

        if (this.departmentList.length > 0) {
          const departmentIdMap = new Map<string, any>();

          this.departmentList.forEach((department: any) => {
            departmentIdMap.set(
              department.department_id.toString(),
              department
            );
          });

          this.userList.forEach((user: any) => {
            let exist = false;

            if (user.department_ids.length > 0) {
              user.department_ids.forEach((deptId: any) => {
                if (departmentIdMap.has(deptId)) {
                  const department = departmentIdMap.get(deptId);
                  const dId = `${deptId}|${user.account}`;

                  dataLeft.push({
                    department_id: dId,
                    parentid: department.department_id,
                    account: user.account,
                    avatar: user.avatar,
                  });

                  exist = true;
                }
              });
            }

            /**
             * 如果用户没有在可见范围部门中，则添加
             */
            if (!exist) {
              dataLeft.push({
                department_id: user.account,
                parentid: 0,
                account: user.account,
                avatar: user.avatar,
              });
            }
          });
        } else {
          this.userList.forEach((row: any) => {
            let data = {
              department_id: row.account,
              parentid: 0,
              account: row.account,
              avatar: row.avatar,
            };

            dataLeft.push(data);
          });
        }

        dataLeft = [...this.departmentList, ...dataLeft];
        this.data = dataLeft;

        /**
         * 递归
         */
        this.dataLeft = recArrTree(this.data, "department_id", "parentid", 0);

        console.log("time", new Date().getTime());

        /**
         * 设置最终选中的值，防止相同数据在不同部门无法选中
         */
        this.setCheckedValue();
        this.setDUTreeShowTrueOrFalse(false);
      });
    },

    /**
     * 获取用户和部门列表  原版容易请求超时
     */
    async initGetDUTreeList(parameters: any = {}) {
      this.data = [];
      this.dataLeft = [];

      getUserAndDepartmentList(parameters).then((res: any) => {
        if (isNotEmpty(res)) {
          /**
           * 保留初始数据
           */
          this.data = res.data;

          /**
           * 递归
           */
          this.dataLeft = recArrTree(res.data, "department_id", "parentid", 0);

          /**
           * 设置最终选中的值，防止相同数据在不同部门无法选中
           */
          this.setCheckedValue();
        }

        this.setDUTreeShowTrueOrFalse(false);
      });
    },

    /**
     * 设置最终选中的值，防止相同数据在不同部门无法选中
     */
    setCheckedValue() {
      let usersData = [];
      let departmentsData = [];

      this.defaultCheckedKeys = [];

      /**
       * 循环初始数据
       */
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index];

        /**
         * 判断是用户还是部门
         */
        if (element.account) {
          const bob = this.usersData.find(
            (person: any) => person.userid === element.account
          );

          if (bob) {
            usersData.push(element.department_id);
          }
        } else {
          const bob = this.departmentsData.find(
            (person: any) => person === element.department_id
          );

          if (bob) {
            departmentsData.push(element.department_id);
          }
        }
      }

      /**
       * 设置多选选中值
       */
      this.defaultCheckedKeys = this.defaultCheckedKeys.concat(
        usersData,
        departmentsData
      );

      /**
       * 进行选中
       */
      this.$nextTick(() => {
        if (this.$refs.treeLeft) {
          (this.$refs.treeLeft as any).setCheckedKeys(this.defaultCheckedKeys);
        }
      });
    },

    /**
     * 点击多选框
     */
    handleCheckChangeDUTree(element: any, elementBloock: any) {
      /**
       * 查找用户或者部门的下标
       */
      let index = element.account
        ? this.usersData.findIndex(
            (tiem: any) => tiem.userid == element.account
          )
        : this.departmentsData.findIndex(
            (tiem: any) => tiem == element.department_id
          );

      /**
       * 根据下标和选中状态进行删除或者添加
       */
      if (-1 < index && elementBloock === false) {
        if (element.account) {
          this.usersData.splice(index, 1);
        } else {
          this.departmentsData.splice(index, 1);
        }

        this.setCheckedValue();
      } else if (-1 == index && elementBloock === true) {
        if (element.account) {
          this.usersData.unshift({
            avatar: element.avatar,
            userid: element.account,
          });
        } else {
          this.departmentsData.unshift(element.department_id);
        }

        this.setCheckedValue();
      }
    },

    /**
     * 右侧数据 点击删除
     */
    handleDeleteDUTree(element: any) {
      /**
       * 查找用户或者部门的下标
       */
      let index = element.userid
        ? this.usersData.findIndex((tiem: any) => tiem.userid == element.userid)
        : this.departmentsData.findIndex((tiem: any) => tiem == element);

      if (-1 < index) {
        if (element.userid) {
          let usersData = this.usersData.filter(
            (item: any) => item.userid !== element.userid
          );

          this.usersData = usersData;
        } else {
          let departmentsData = this.departmentsData.filter(
            (item: any) => item !== element
          );

          this.departmentsData = departmentsData;
        }

        this.setCheckedValue();
      }
    },

    /**
     * 右侧数据 点击清空，清空数据
     */
    onEmptyDUTree() {
      this.usersData = [];
      this.departmentsData = [];
      this.setCheckedValue();
      this.setDUTreeShowTrueOrFalse(true);
      this.setDUTreeShowTrueOrFalse(false);
    },

    /**
     * 点击保存使用父级页面方法关闭当前页
     */
    onSubmitDUTree() {
      this.$emit(
        "handleChangeDUTreeFunc",
        this.usersData,
        this.departmentsData
      );
      this.$emit("openDUTree", "");

      this.setDUTreeShowTrueOrFalse(true);

      setTimeout(() => {
        this.dialogDUTreeShow = false;
      }, 1000);
    },

    /**
     * 点击取消，使用父级页面方法关闭当前页
     */
    onCancelDUTree() {
      this.$emit("openDUTree", "");
      this.dialogDUTreeShow = false;
    },

    /**
     * 显示还是隐藏
     * @param bools true 显示限制，false 不显示不限制
     * @param msg 提示消息
     */
    setDUTreeShowTrueOrFalse(bools = true, msg = "") {
      if (bools) {
        this.loading = bools;
      } else {
        /**
         * 消息提示
         */
        if (msg) {
          ElMessage.error(msg);
        }
        /**
         * 隐藏全局遮蔽
         */
        setTimeout(() => {
          this.loading = bools;
        }, 500);
      }
    },
  },
});
</script>
<style>
.el-dialog__body {
  padding: 0px var(--el-dialog-padding-primary) var(--el-dialog-padding-primary);
}
.el-dialog__footer {
  border-top: 1px solid #ebebeb;
}
</style>
<style lang="less" scoped>
@import "../../styles/less/flow-tree.less";

.treeTransfer {
  // display: flex;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  .mg10 {
    margin: 10px;
  }
  .leftTree,
  .rightTree {
    flex-grow: 1;
    width: calc(50% - 15px);
    min-width: 300px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    height: 680px;

    .treeTit {
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      background: #e3e7ee;
      padding-left: 20px;
    }
    .list {
      height: 580px;
      // padding: 24px 10px 24px 0px;
      min-width: 300px;
    }
  }
  .btnDiv {
    width: 30px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.listItem {
  cursor: pointer;
  padding: 10px 20px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: #accdff;
  }
}
</style>
